// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendarAlt, faEdit, faUser } from '@fortawesome/free-solid-svg-icons';
// import { baseUrl } from './../App';
// import imgp10 from '../assets/imgp10.PNG';
// import TopBar from './TopBar';
// // import BlurredImage from './ProgressiveBlurImage';
// import CrescendoProgressiveImage from './CrescendoProgressiveImage';
// import ArticleForm from './ArticleForm';
// import Footer from './Footer';
// // const NewsWrapper = styled.div`
// //   max-width: 800px;
// //   margin: 0 auto;
// //   padding: 20px;
// //   background-color: dimgrey;
// // `;

// // const NewsItem = styled.div`
// //   border-bottom: 1px solid #ccc;
// //   padding: 20px;
// //   display: flex;
// //   align-items: flex-start;
// // `;

// // const NewsImage = styled.img`
// //   width: 150px;
// //   height: auto;
// //   margin-right: 20px;
// // `;

// // const NewsContent = styled.div`
// //   flex: 1;
// //   color: aliceblue;
// // `;

// // const NewsTitle = styled.h3`
// //   margin: 0;
// //   color: yellowgreen;
// // `;

// // const NewsMeta = styled.div`
// //   display: flex;
// //   align-items: center;
// //   font-size: 12px;
// //   color: yellow;
// // `;

// // const NewsIcon = styled(FontAwesomeIcon)`
// //   margin-right: 5px;
// // `;
// const NewsContent = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   /* width: 100%; */
  
//   height: 100%;
//   padding: 20px;
//   background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity */
//   color: white;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   opacity: 0; /* Initially hidden */
//   transition: opacity 0.5s ease; /* Transition for opacity change */
// `;

// const NewsItem = styled.div`
//   position: relative;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   margin-bottom: 20px;
//   overflow: hidden; /* Ensure overflow doesn't disrupt layout */
//   background-color: white;
//   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
//   cursor: pointer;

//   &:hover ${NewsContent} {
//     opacity: 1; /* Show content on hover */
//   }
// `;
// const EditIcon = styled(FontAwesomeIcon)`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
// `;
// const NewsWrapper = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 20px;
//   background-color: #f5f5f5;
// `;

// // const NewsItem = styled.div`
// //   border: 1px solid #ddd;
// //   border-radius: 5px;
// //   margin-bottom: 20px;
// //   padding: 20px;
// //   display: flex;
// //   align-items: center;
// //   background-color: white;
// //   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
// // `;

// // const NewsImage = styled.img`
// //   width: 150px;
// //   height: auto;
// //   border-radius: 5px;
// // `;

// // const NewsContent = styled.div`
// //   flex: 1;
// //   color: #333;
// // `;

// const NewsTitle = styled.h3`
//   margin: 0;
//   color: #333;
// `;

// const NewsMeta = styled.div`
//   display: flex;
//   align-items: center;
//   font-size: 12px;
//   color: #777;
//   margin-top: 10px;
// `;

// const NewsIcon = styled(FontAwesomeIcon)`
//   margin-right: 5px;
// `;

// const News = () => {
//   const [articles, setArticles] = useState([]);
//   const [showAddForm, setShowAddForm] = useState(false);
//   useEffect(() => {
//     axios.get(`${baseUrl}/api/article`).then((response) => {
//       setArticles(response.data);
//     });
//   }, []);
//   const handleEdit = (articleId) => {
//     // Logic to handle edit, e.g., show an edit modal
//   };

//   return (
//     <>

//     <TopBar/>
//     <NewsWrapper>
//     {showAddForm && <ArticleForm />} {/* Show the form only if showAddForm is true */}
        
//         <button onClick={() => setShowAddForm(true)}>Add New Article</button>   
//       <ArticleForm/>
      
//       {articles.map((article) => (
//         <NewsItem key={article.id}>
//       <CrescendoProgressiveImage  src={`${baseUrl}`+ /images/+ article.image}  alt="Image Floue" />
//            {/* <NewsImage src={`${baseUrl}`+ /images/+ article.image} alt={article.title} />  */}
//            <EditIcon icon={faEdit} onClick={() => handleEdit(article.id)} />
//           <NewsContent>
//             <NewsTitle>{article.title}</NewsTitle>
//             <p>{article.content}</p>
//             <NewsMeta>
//               <NewsIcon icon={faCalendarAlt} />
//               {article.date} | <NewsIcon icon={faUser} /> {article.author}
//             </NewsMeta>
//           </NewsContent>
//         </NewsItem>
//       ))}
//     </NewsWrapper>
//     <Footer/>
//     </>
//   );
// };

// export default News;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEdit, faUser } from '@fortawesome/free-solid-svg-icons';
import { baseUrl } from './../App';
import TopBar from './TopBar';
// import CrescendoProgressiveImage from './CrescendoProgressiveImage';
import ArticleForm from './ArticleForm';
import Footer from './Footer';
// import ArticleComponent from './ArticleComponent';

const NewsContent = styled.div`
  position: absolute; 
  top: 30px;
  left: 0;
  height: 95%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0.8;
  width: -webkit-fill-available;
  transition: opacity 0.5s ease;
`;
const NewsItemContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const NewsItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover ${NewsContent} {
    opacity: 1;
  }
`;

const EditIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;


  background-color: pink;
`;

const NewsWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  /* background-color: #f5f5f5; */
  padding: 80px 0 0 0;
`;

const NewsTitle = styled.h3`
  margin: 0;
  color: yellow;
`;

const NewsMeta = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #777;
  margin-top: 10px;
`;

const NewsIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const News = () => {
  const [articles, setArticles] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState(null);

  useEffect(() => {
    axios.get(`${baseUrl}/api/article`).then((response) => {
      setArticles(response.data);
    });
  }, []);
  const handleEdit = (articleId) => {
    setSelectedArticleId(articleId);
    setIsEditModalVisible(true);
  };


  return (
    <>
      <TopBar />
      <NewsWrapper>
        {showAddForm && <ArticleForm />}
        {isEditModalVisible && <ArticleForm articleId={selectedArticleId} />}

        <button onClick={() => setShowAddForm(true)}>Add New Article</button>
        {articles.map((article) => (
        <NewsItemContainer key={article.id}>
        <NewsItem>
          
            <img
src={`${baseUrl}/images/${article.image}`} alt="Image Floue" />
            <EditIcon icon={faEdit} onClick={() => handleEdit(article.id)} />
            
            <NewsContent>
              <NewsTitle>{article.title}</NewsTitle>
              <p>{article.content}</p>
              <NewsMeta>
                <button icon={faCalendarAlt} />
                {article.date} | <NewsIcon icon={faUser} /> {article.author}
              </NewsMeta>
            </NewsContent>
            </NewsItem>
          </NewsItemContainer>
        ))}
      </NewsWrapper>
      {/* <ArticleComponent/> */}
      <Footer />
    </>
  );
};

export default News;
