// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import Product from './components/Product';

// const ProductDetails = () => {
//     const { productId } = useParams();
//     const [product, setProduct] = useState(null);
//     console.log(productId)

//     useEffect(() => {
//         // Fetch the details of the product based on the productId
//         axios.get(`${baseUrl}/api/products/${productId}`)
//             .then((response) => {
//                 setProduct(response.data);
//             })
//             .catch((error) => {
//                 console.error('Error fetching product details:', error);
//             });
//     }, [productId]);

//     // If the product data is still being fetched, show a loading message
//     if (!product) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <Product product={product} />

//     );
// };

// export default ProductDetails;
// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaTrash, FaShoppingCart } from 'react-icons/fa'; // Assuming you're using Font Awesome icons
// import { useCart } from './useCart'; // Custom hook to manage cart state
// import { baseUrl } from './constants'; 
 import styled from 'styled-components';
// import { FaShoppingCart, FaTrash } from 'react-icons/fa';
 import { useCart } from '../CarteContext';
// import Product from '../components/Product';
 import TopBar from '../components/TopBar';
 import { baseUrl } from '../App';
 import Footer from './../components/Footer';


const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #E9B529;
  border-radius: 5px;
  padding: 20px;
  width: 50%;
  height: 100%;
  margin: 50px auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProductImage = styled.img`

  object-fit: cover;
  border-radius: 25px;

`;

const ProductName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const ProductPrice = styled.p`
  font-size: 30px;
  margin-bottom: 0px;
`;

const ProductCenseil = styled.p`
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  
  z-index: 999;
`;
const ProductDescription = styled.p`
   
  background-color: pink;
  border-radius: 10px;
  padding: 10px;


`;
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 40px;
  width: 50%;
  height: 100%;
  margin: 50px auto;
  cursor: pointer;
`;
const ProductCategory = styled.p`
  font-size: 20px;
  margin-bottom: 5px;
`;

const ProductBrand = styled.p`
  font-size: 20px;
  margin-bottom: 5px;
`;

const ProductRating = styled.p`
  font-size: 20px;
  margin-bottom: 5px;
`;

const ProductReviews = styled.div`
  font-size: 20px;
`;

const CartIcon = styled.div`
  font-size: 28px;
  color: #333;
  padding: 1%;
`;


const ProductDetails = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { cartItems, setCartItems } = useCart();
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/products/${productId}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/cart`);
        setCartItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCartItems();
  }, [setCartItems]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const addToCart = (product) => {
    axios.post(
      `${baseUrl}/api/cart`,
      {
        productId: product.id,
        userId: user.id,
        quantity: 1,
        name: product.name,
        price: product.price,
        image: product.image
      }
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log("Product added to cart:", response.data);
          setCartItems((prevCartItems) => [...prevCartItems, response.data]);
          // You might want to update your cart state here if needed
        }
      })
      .catch((error) => {
        console.log("Error adding product to cart:", error);
      });
  };


  const removeFromCart = (itemId) => {
    console.log('Removing item with ID:', productId);

    // Remove the item from the cart on the server
    axios.delete(`${baseUrl}/api/cart/${itemId}`)
      .then(() => {
        console.log('Item removed successfully');

        // Attempt to update cart items state by filtering out the removed item
        setCartItems((prevCartItems) => {
          console.log('Previous cart items:', prevCartItems);

          const filteredItems = prevCartItems.filter((item) => {
            // Convert both ids to the same type (number) before comparison
            const itemToRemove = Number(product.id);
            const itemIdNumber = Number(itemId);
            console.log(`Checking if item ${itemToRemove} equals ${itemIdNumber}:`, itemToRemove === itemIdNumber);

            // Keep the item if its id does not match the itemId
            return itemToRemove !== itemIdNumber;
          });

          console.log('Filtered cart items:', filteredItems);
          return filteredItems;
        });
      })
      .catch((error) => {
        console.error("Error removing product from cart:", error);
      });
  };

  const isInCart = cartItems.some((item) => item.productId === 72);
  const cartItemsCount = cartItems.length;
  return (
    <>
      <TopBar cartItemsCount={cartItemsCount} />
      
      <ProductContainer>

        <ProductImage src={baseUrl + '/images/' + product.image} alt={product.name} />
        <ProductInfo>

        <ProductName>{product.name}</ProductName>
        <ProductPrice>{product.price} €/kg</ProductPrice>

        <ProductCategory>Catégorie: {product.category}</ProductCategory>
        <ProductBrand>Marque: {product.brand}</ProductBrand>
        {product.reviews?.length} 
        <ProductRating>Basé sur 546 avis</ProductRating>
        <ProductReviews>
          VOIR LES AVIS
        </ProductReviews>

          {isInCart ? (
            <CartIcon onClick={() => removeFromCart(product.id)}>
              <FaTrash />
              Remove from Cart
            </CartIcon>
          ) : (
              <CartIcon onClick={() => addToCart(product)}>
                <FaShoppingCart />
                {cartItemsCount} Add to Cart
            </CartIcon>
          )}
        <CartIcon onClick={() => removeFromCart(product.id)}>
          <FaTrash />
          </CartIcon>
          <ProductDescription>{product.description}</ProductDescription>

        </ProductInfo>
      </ProductContainer>
      
      <ProductCenseil>
        Conseil de dégustation :

        Nos pâtisseries se conservent jusqu’à trois semaines au bas de votre réfrigérateur.
        Nous vous recommandons de les sortir 1/2 heure avant la dégustation afin de profiter pleinement de leurs textures et de leurs saveurs.
      </ProductCenseil>
      <Footer />
    </>
  );
};

export default ProductDetails;
