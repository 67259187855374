// // App.js (Main component)

// import React, { useContext } from 'react';

// import { Routes, Route, Navigate } from 'react-router-dom';
// import Home from './pages/Home';


// import { ProductsProvider } from './ProductsContext';
// // <-- Import the ShoppingCartPage component
// import ShoppingCart from './components/ShoppingCart';
// import Store from './pages/Store';
// import ProductDetails from './pages/ProductDetails';
// import Register from './pages/register/Register';
// import Login from './pages/login/Login';
// import { useState } from 'react';
// import { AuthProvider } from './context/AuthContext';
// const nodeEnv = process.env.NODE_ENV;
// let ioUrl;

// if (nodeEnv === 'production') {
//     ioUrl = process.env.REACT_APP_IO_URL || 'https://servecom.https://servecom.onrender.com.com;
// } else {
//     ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
// }

// export const baseUrl = ioUrl;
// export const AuthContext = React.createContext();

// const initialState = {
//   isAuthenticated: false,
//   isAdmin: false,
//   user: JSON.parse(localStorage.getItem("user")) || null,
//   // admin: JSON.parse(localStorage.getItem("admin")) || null,
//   token: null,
// };
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       // localStorage.setItem("user", JSON.stringify(action.payload.user));
//       // localStorage.setItem("token", JSON.stringify(action.payload.token));

//       return {
//         ...state,
//         isAdmin: true,
//         isAuthenticated: true,
//         user: action.payload.user,
//         token: action.payload.token,
//       };
//     case "X":
//       localStorage.clear();
//       return {
//         ...state,
//         isAuthenticated: false,
//         user: null,
//       };
//     default:
//       return state;
      
//   }
  
// };





// const  App = () => {
//       const [state, dispatch] = React.useReducer(reducer, initialState);
//   React.useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("user") || null);
//     const token = (localStorage.getItem("token") || null);
//     if (user && token) {
//       dispatch({
//         type: "LOGIN",
//         payload: {
//           user,
//           token,
//         },
//       });
//     }
//   }, []);
//   console.log(state.isAuthenticated)
//     return (
//     // <Router basename="/">

//         <AuthProvider>
//         <ProductsProvider>
//             <Routes>
//                 {/* Check if user is authenticated */}
//                 {state.isAuthenticated ? (
//                     <>
//                         <Route path="/" element={<Home />} />
//                         <Route path="/store" element={<Store />} />
//                         <Route path="/product/:productId" element={<ProductDetails />} />
//                         <Route path="/cart" element={<ShoppingCart />} />
//                     </>
//                 ) : (
//                     <>
//                         {/* If not authenticated, redirect to login */}

//                         <Route path="/" element={<Navigate to="/login" />} />
//                         <Route path="/login" element={<Login/>} /> 
//                         <Route path="/register" element={<Register />} />
//                     </>
//                 )}
//             </Routes>
//         </ProductsProvider>
//         </AuthProvider>
//     );
// };

// export default App;
import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import { ProductsProvider } from './ProductsContext';
import ShoppingCart from './components/ShoppingCart';
import Store from './pages/Store';
import ProductDetails from './pages/ProductDetails';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import { CartProvider } from './CarteContext';

import { AuthProvider, useAuthState } from './context/AuthContext'; //// Assuming you have your AuthContext correctly imported
import News from './components/News';
const nodeEnv = process.env.NODE_ENV;

let ioUrl;

if (nodeEnv === 'production') {
  ioUrl = process.env.REACT_APP_IO_URL || 'https://servecom.onrender.com'
} else {
    ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
}

export const baseUrl = ioUrl;

  const App = () => {
    const { isAuthenticated } = useAuthState('');

    // console.log(isAuthenticated)
    // const navigate = useNavigate();
    // useEffect(() => {
    //   // Redirect to home if already authenticated
    //   if (isAuthenticated) {
    //      navigate('/');
    //   }
    // }, [isAuthenticated]);

    return (
      <AuthProvider>
        <ProductsProvider>
          <CartProvider>
       <Routes  >
              <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />

              <Route path="/store" element={isAuthenticated ? <Store /> : <Navigate to="/login" />} />

              <Route path="/product/:productId" element={isAuthenticated ? <ProductDetails /> : <Navigate to="/login" />} />

              <Route path="/cart" element={isAuthenticated ? <ShoppingCart /> : <Navigate to="/login" />} />
              <Route path="/news" element={isAuthenticated ? <News /> : <Navigate to="/login" />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        </CartProvider>
        </ProductsProvider>
      </AuthProvider>
    );
  };
  
  export default App;