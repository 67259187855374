// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import styled from 'styled-components';

// // const HomeContainer = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// // `;

// // const ImageGridContainer = styled.div`
// //   display: grid;
// //   grid-template-columns: repeat(5, 1fr);
// //   grid-gap: 20px;
// // `;

// // const ItemContainer = styled.div`
// //   position: relative;
// //   background-color: #f0f0f0;
// //   padding: 10px;
// //   border-radius: 5px;
// //   cursor: pointer;
// //   transition: transform 0.3s ease-in-out;

// //   &:hover {
// //     transform: scale(1.1);
// //   }
// // `;

// // const ItemImage = styled.img`
// //   width: 100%;
// //   height: 150px;
// //   object-fit: cover;
// //   border-radius: 5px;
// // `;

// // const ItemInfo = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   margin-top: 10px;
// // `;

// // const ItemName = styled.div`
// //   font-size: 16px;
// //   font-weight: bold;
// //   margin-bottom: 5px;
// // `;

// // const ItemPrice = styled.div`
// //   font-size: 14px;
// // `;

// // const CartIcon = styled.div`
// //   position: absolute;
// //   top: 10px;
// //   right: 10px;
// //   font-size: 24px;
// //   color: #333;
// // `;

// // const Home = () => {
// //   const items = [
// //     {
// //       name: 'Item 1',
// //       price: '$10',
// //       image: 'https://via.placeholder.com/150x150?text=Item1',
// //     },
// //     {
// //       name: 'Item 2',
// //       price: '$15',
// //       image: 'https://via.placeholder.com/150x150?text=Item2',
// //     },
// //     {
// //       name: 'Item 3',
// //       price: '$20',
// //       image: 'https://via.placeholder.com/150x150?text=Item3',
// //     },
// //     // Add more items here
// //   ];
// //   const [products, setProducts] = useState([]);
// //   const [cartItems, setCartItems] = useState([]);

// //   useEffect(() => {
// //     // Fetch products data from the server
// //     axios.get(`${baseUrl}/api/products`).then((response) => {
// //       setProducts(response.data);
// //     });

// //     // Fetch cart items from the server
// //     axios.get(`${baseUrl}/api/cart`).then((response) => {
// //       setCartItems(response.data);
// //     });
// //   }, []);

// //   const addToCart = (product) => {
// //     // Add the selected product to the cart on the server
// //     axios.post(`${baseUrl}/api/cart`, { product }).then((response) => {
// //       setCartItems(response.data);
// //     });
// //   };

// //   const removeFromCart = (itemId) => {
// //     // Remove the item from the cart on the server
// //     axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
// //       setCartItems(response.data);
// //     });
// //   };
// //   return (
// //     <HomeContainer>
// //       <h1>Welcome to Our Store</h1>
// //       <div>
// //         <h1>Shopping Cart App</h1>
// //         <div className="product-list">
// //           {products.map((product) => (
// //             <div key={product.id} className="product">
// //               <img src={product.image} alt={product.name} />
// //               <h3>{product.name}</h3>
// //               <p>${product.price}</p>
// //               <button onClick={() => addToCart(product)}>Add to Cart</button>
// //             </div>
// //           ))}
// //         </div>
// //         <div className="cart">
// //           <h2>Cart</h2>
// //           <ul>
// //             {cartItems.map((item) => (
// //               <li key={item.id}>
// //                 <img src={item.product.image} alt={item.product.name} />
// //                 <div>
// //                   <h4>{item.product.name}</h4>
// //                   <p>${item.product.price}</p>
// //                   <button onClick={() => removeFromCart(item.id)}>Remove</button>
// //                 </div>
// //               </li>
// //             ))}
// //           </ul>
// //         </div>
// //       </div>
// //       <ImageGridContainer>
// //         {items.map((item, index) => (
// //           <ItemContainer key={index}>
// //             <ItemImage src={item.image} alt={item.name} />
// //             <CartIcon>&#x1F6D2;</CartIcon>
// //             <ItemInfo>
// //               <ItemName>{item.name}</ItemName>
// //               <ItemPrice>{item.price}</ItemPrice>
// //             </ItemInfo>
// //           </ItemContainer>
// //         ))}
// //       </ImageGridContainer>
// //     </HomeContainer>
// //   );
// // };

// // export default Home;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import TopBar from './components/TopBar';

// const HomeContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
// `;

// const ProductList = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 20px;
//   margin-top: 20px;
// `;

// const ProductCard = styled.div`
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   padding: 10px;
//   cursor: pointer;
//   transition: transform 0.3s ease-in-out;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const ProductImage = styled.img`
//   width: 100%;
//   height: 200px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

// const ProductName = styled.h3`
//   font-size: 16px;
//   font-weight: bold;
//   margin: 10px 0;
// `;

// const ProductPrice = styled.p`
//   font-size: 14px;
//   margin-bottom: 10px;
// `;

// const CartIcon = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   font-size: 24px;
//   color: #333;
// `;

// const CartContainer = styled.div`
//   margin-top: 20px;
//   width: 100%;
//   max-width: 600px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   padding: 10px;
// `;

// const CartTitle = styled.h2`
//   font-size: 18px;
//   font-weight: bold;
//   margin-bottom: 10px;
// `;

// const CartItem = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 10px;
// `;

// const CartItemInfo = styled.div`
//   flex-grow: 1;
//   margin-left: 10px;
// `;

// const CartItemImage = styled.img`
//   width: 60px;
//   height: 60px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

// const CartItemName = styled.h3`
//   font-size: 14px;
//   font-weight: bold;
//   margin-bottom: 5px;
// `;

// const CartItemPrice = styled.p`
//   font-size: 12px;
//   margin-bottom: 5px;
// `;

// const RemoveButton = styled.button`
//   font-size: 12px;
//   background-color: #ff5851;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   padding: 5px 10px;
//   cursor: pointer;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #d4433f;
//   }
// `;

// const Home = () => {
//   const [products, setProducts] = useState([]);
//   const [cartItems, setCartItems] = useState([]);

//   useEffect(() => {
//     // Fetch products data from the server
//     axios.get(`${baseUrl}/api/products`).then((response) => {
//       setProducts(response.data);
//     });

//     // Fetch cart items from the server
//     axios.get(`${baseUrl}/api/cart`).then((response) => {
//       setCartItems(response.data);
//     });
//   }, []);

//   const addToCart = (product) => {
//     // Add the selected product to the cart on the server
//     axios.post(`${baseUrl}/api/cart`, { product }).then((response) => {
//       setCartItems(response.data);
//     });
//   };

//   const removeFromCart = (itemId) => {
//     // Remove the item from the cart on the server
//     axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
//       setCartItems(response.data);
//     });
//   };

//   return (<>
//     <TopBar />
//     <HomeContainer>

//       <h1>Welcome to Our Store</h1>
//       <ProductList>
//         {products.map((product) => (
//           <ProductCard key={product.id} onClick={() => addToCart(product)}>
//             <ProductImage src={product.image} alt={product.name} />
//             <ProductName>{product.name}</ProductName>
//             <ProductPrice>${product.price}</ProductPrice>
//           </ProductCard>
//         ))}
//       </ProductList>
//       <CartContainer>
//         <CartTitle>Cart</CartTitle>
//         {cartItems.map((item) => (
//           <CartItem key={item.id}>
//             <CartItemImage src={item.product.image} alt={item.product.name} />
//             <CartItemInfo>
//               <CartItemName>{item.product.name}</CartItemName>
//               <CartItemPrice>${item.product.price}</CartItemPrice>
//               <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
//             </CartItemInfo>
//           </CartItem>
//         ))}
//       </CartContainer>

//     </HomeContainer>
//   </>
//   );
// };

// export default Home;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import TopBar from '../components/TopBar';
import { useCart } from '../CarteContext';
import ImageSlider from '../components/ImageSlider';
import Footer from '../components/Footer';
import Product from '../components/Product';
import { ProductsContext } from '../ProductsContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
// import Animation from './Animation';
import img3 from '../assets/img24.jpg';
import img4 from '../assets/img99.png';
import imgp8 from '../assets/imgp8.PNG';
import imgp9 from '../assets/imgp9.PNG';
import imgp10 from '../assets/imgp10.PNG';
// import ImageRowAnimation from './ImageRowAnimation';
import ImageAnimation from './ImageAnimation';
import SpriteSheetAnimation from '../components/SpriteSheetAnimation';
import { baseUrl } from '../App';
import ProductPostForm from '../components/ProductPostForm';

const images = [imgp8, imgp9, imgp10];
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
`;

const ProductCard = styled.div`

  border: 1px solid #E9B529;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const CartIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #333;
`;

const CartContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
   margin-bottom: 50px;
`;

const CartTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CartItemInfo = styled.div`
  flex-grow: 1;
  margin-left: 10px;
`;

const CartItemImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
`;

const CartItemName = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CartItemPrice = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  font-size: 12px;
  background-color: #ff5851;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d4433f;
  }
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: arial, sans-serif;
`;

const Loader = styled.div`
  width: 60px;
`;

const LoaderWheel = styled.div`
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
`;

const LoaderText = styled.div`
  &:after {
    content: 'Loading';
    animation: load 2s linear infinite;
  }
`;

const Store = () => {

  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));

  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };
  // const [products, setProducts] = useState([]);
  const products = useContext(ProductsContext);

  const { cartItems, setCartItems } = useCart();

  useEffect(() => {

    // Fetch cart items from the server
    axios.get(`${baseUrl}/api/cart`).then((response) => {
      setCartItems(response.data);
      // setCartItemsCount(response.data.length); // Update the cart items count
    });
  }, [setCartItems]);

  // const addToCart = (productId) => {
  //   // Add the selected product to the cart on the server
  //   axios.post(`${baseUrl}/api/cart`, { productId }).then((response) => {
  //     setCartItems(response.data);
  //   });
  // };
  const addToCart = (product) => {
    axios.post(
      `${baseUrl}/api/cart`,
      {

        productId: product.id,
        userId: user.id,
        quantity: 1,
        name: product.name,
        price: product.price,
        image: product.image
      }
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log("Product added to cart:", response.data);
          setCartItems((prevCartItems) => [...prevCartItems, response.data]);
          // You might want to update your cart state here if needed
        }
      })
      .catch((error) => {
        console.log("Error adding product to cart:", error);
      });
  };

  // const removeFromCart = (itemId) => {
  //   // Remove the item from the cart on the server
  //   axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
  //     setCartItems(response.data);
  //   });
  // };
  const removeFromCart = (itemId) => {
    // Remove the item from the cart on the server
    axios.delete(`${baseUrl}/api/cart/${itemId}`).then(() => {
      // Update the cart items state by filtering out the removed item
      setCartItems((prevCartItems) => prevCartItems.filter((item) => item.id !== itemId));
    });
  };

  // console.log(cartItemsCount)
  // Calculate the cart items count
  const cartItemsCount = cartItems.length;
  // Wait for the data to be loaded before rendering the child component
  if (products.length === 0) {
    return <Loading>
      <Loader>
        <LoaderWheel />
      </Loader>
      <LoaderText>Loading...</LoaderText>
    </Loading>;
  }
  const cartArray = Object.values(cartItems)
  console.log(cartItems)
  return (
    <>
      <TopBar cartItemsCount={cartItemsCount} />
      <ProductPostForm />

      {/* <ImageSlider /> */}
      <HomeContainer>
        {/* <Animation /> */}
        {/* <ImageAnimation images={images} /> */}
        {/* <SpriteSheetAnimation /> */}
        <h4>Assortiments</h4>
        <h1>Découvrez nos assortiments de pâtisseries orientales</h1>
        <ProductList>
          {products.map((product, index) => (

            // Use the Link component to navigate to the product details page
            <div key={index}>
              <Link to={`/product/${product.id}`}>
                <ProductCard>
                  <ProductImage src={baseUrl + '/images/' + product.image} alt={product.name} />
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>${product.price}</ProductPrice>

                </ProductCard>
                
              </Link>
              <button onClick={() => addToCart(product)}>Add to Cart</button>

            </div>
          ))}


        </ProductList>
        {/* 
        {products.map((product) => (
          <Product key={product.id} product={product} />
        ))} */}

        <CartContainer>
          <CartTitle>Cart</CartTitle>
          {cartArray.map((item) => (
            <CartItem key={item.id}>
              <CartItemImage src={baseUrl + '/images/' + item.image} alt={item.name} />
              <CartItemInfo>
                <CartItemName>{item.id}</CartItemName>
                <CartItemPrice>{item.price}</CartItemPrice>
                <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
              </CartItemInfo>
            </CartItem>
          ))}
        </CartContainer>
        {/* <ShoppingCart cartItems={cartItems} setCartItems={setCartItems} /> */}
      </HomeContainer >

      < Footer />
    </>
  );
};

export default Store;
