
// import { FaBars, FaShoppingCart } from 'react-icons/fa';
// import React, { useState } from 'react';
// import styled from 'styled-components';
// const TopBarWrapper = styled.div`
//   background-color: #be437c;
//   color: #fff;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px;

// `;

// const Logo = styled.div`
//   font-size: 24px;
//   font-weight: bold;
// `;

// const Navbar = styled.nav`
//   ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     display: flex;
//   }

//   li {
//     margin-right: 20px;
//   }

//   a {
//     text-decoration: none;
//     color: #fff;
//     transition: color 0.3s;

//     &:hover {
//       color: #ddd;
//     }
//   }

//   /* Show the navigation menu when burger menu is open */
//   &.open {
//     display: block;
//   }

//   /* Media query to hide the navigation menu on smaller screens */
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// const BurgerIcon = styled.div`
//   font-size: 24px;
//   cursor: pointer;
//   display: none;

//   /* Show the burger menu icon on smaller screens */
//   @media (max-width: 768px) {
//     display: block;
//   }
// `;

// const ShoppingCartIcon = styled.div`
//   font-size: 24px;
//   cursor: pointer;
// `;

// const CartItemsCount = styled.span`
//   background-color: #fff;
//   color: #be437c;
//   font-size: 12px;
//   font-weight: bold;
//   padding: 2px 6px;
//   border-radius: 50%;
//   position: absolute;
//   top: -5px;
//   right: -5px;
// `;
// const TopBar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [cartItemsCount, setCartItemsCount] = useState(0); // State to store the number of cart items

//   const handleBurgerClick = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   // Function to update the number of cart items
//   const updateCartItemsCount = (count) => {
//     setCartItemsCount(count);
//   };

//   return (
//     <TopBarWrapper>
//       <Logo>Your Logo</Logo>
//       <Navbar className={isMenuOpen ? 'open' : ''}>
//         <ul>
//           <li>
//             <a href="./store">Home</a>
//           </li>
//           <li>
//             <a href="#about">About</a>
//           </li>
//           <li>
//             <a href="#services">Services</a>
//           </li>
//           <li>
//             <a href="#contact">Contact</a>
//           </li>
//         </ul>
//       </Navbar>
//       <BurgerIcon onClick={handleBurgerClick}>
//         <FaBars />
//       </BurgerIcon>
//       {/* Add the shopping cart icon and number of items */}
//       <ShoppingCartIcon>
//         <FaShoppingCart />
//         {cartItemsCount > 0 && <CartItemsCount>{cartItemsCount}</CartItemsCount>}
//       </ShoppingCartIcon>
//     </TopBarWrapper>
//   );
// };

// export default TopBar;
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaBars, FaShoppingCart } from 'react-icons/fa';
import { BrowserRouter, useNavigate, Link } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import MenuBurger from './menuBurger/MenuBurger'
import ProductPostForm from './ProductPostForm';
const TopBarWrapper = styled.div`
  background-color: #E9B529;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;



const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Navbar = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  li {
    margin-right: 20px;
  }

  a {
    text-decoration: none;
    color: #000;
    transition: color 0.3s;

    &:hover {
      color: #ddd;
    }
  }

  /* Show the navigation menu when burger menu is open */
  &.open {
    display: block;
  }

  /* Media query to hide the navigation menu on smaller screens */
  @media (max-width: 768px) {
    display: block;
  }
`;

const BurgerIcon = styled.li`
  font-size: 24px;
  cursor: pointer;
  display: none;
color: aqua;
  /* Show the burger menu icon on smaller screens */
  @media (max-width: 768px) {
    display: block;
  }
`;

const ShoppingCartIcon = styled.div`
  font-size: 24px;
  cursor: pointer;
  position: relative;

  svg {
    margin-right: 5px;
  }
`;

const CartItemsCount = styled.span`
  background-color: #fff;
  color: #be437c;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
`;

const TopBar = ({ cartItemsCount }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [cartItemsCount, setCartItemsCount] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [open, setOpen] = useState(false);
  const dropdownwrapper = useRef(null);
  const handleBurgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownwrapper.current && !dropdownwrapper.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Function to update the number of cart items
  // const updateCartItemsCount = (count) => {
  //   setCartItemsCount(count);
  // };
  let navigate = useNavigate();
  return (
    <TopBarWrapper>
 
      <Navbar className={isMenuOpen ? 'open' : ''}>
        <ul>
          <li>
            <Logo>      <MenuBurger /></Logo>
          </li>
          <li>
            <Logo onClick={() => { navigate("/") }}>Home</Logo>
            <i></i>
          </li>
          <li>
            <Logo onClick={()=>{navigate("./store")}}>Store</Logo> 
          </li>
          <li>
            <Logo href="#about">About</Logo>
          </li>
          <li>
            <Logo onClick={() => setShowAddForm(true)}>Add New product</Logo>
            {showAddForm && <ProductPostForm />}

          </li>
          <li>
            <Logo href="#contact">Contact</Logo> 
          </li>
          <li onClick={handleBurgerClick}>
            {/* <li>
              <FaBars />

            </li> */}
          </li>
          <ShoppingCartIcon onClick={() => {
            navigate("/cart");
          }}>
            <FaShoppingCart />
            {cartItemsCount > 0 && <CartItemsCount>{cartItemsCount}</CartItemsCount>} Cart
          </ShoppingCartIcon>
        </ul>
        
      </Navbar>

      {/* Add the shopping cart icon and number of items */}

    </TopBarWrapper>
  );
};

export default TopBar;
