// import React, { useState } from 'react';
// import styled from 'styled-components';
// import { baseUrl } from '../App';

// // Styled components
// const Form = styled.form`
//   max-width: 500px;
//   margin: 20px auto;
//   padding: 20px;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   background-color: #f9f9f9;
// `;

// const Label = styled.label`
//   display: block;
//   margin-bottom: 8px;
//   color: #333;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 8px;
//   margin-bottom: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// const Select = styled.select`
//   width: 100%;
//   padding: 8px;
//   margin-bottom: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// const TextArea = styled.textarea`
//   width: 100%;
//   height: 100px;
//   padding: 8px;
//   margin-bottom: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// const Button = styled.button`
//   width: 100%;
//   padding: 10px;
//   background-color: #007bff;
//   color: white;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;

//   &:hover {
//     background-color: #0056b3;
//   }
// `;


// const FileInput = styled.input`
//   display: none;
// `;
// const FormContainer = styled.div`
//   max-width: 400px;
//   margin: 0 auto;
//   padding: 20px;
//   background-color: #f5f5f5;
//   border-radius: 8px;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
// `;

// const FormTitle = styled.h2`
//   font-size: 24px;
//   margin-bottom: 20px;
// `;

// const FormGroup = styled.div`
//   margin-bottom: 20px;
// `;

// // const Label = styled.label`
// //   font-size: 18px;
// //   display: block;
// //   margin-bottom: 6px;
// // `;

// // const Input = styled.input`
// //   width: 100%;
// //   padding: 10px;
// //   font-size: 16px;
// //   border: 1px solid #ccc;
// //   border-radius: 4px;
// // `;
// const FileInputLabel = styled.label`
//   background-color: #007bff;
//   color: white;
//   padding: 10px 20px;
//   border-radius: 4px;
//   cursor: pointer;
// `;

// const PreviewImage = styled.img`
//   max-width: 100%;
//   height: auto;
//   margin-top: 10px;
// `;

// const SubmitButton = styled.button`
//   background-color: #007bff;
//   color: white;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 4px;
//   font-size: 18px;
//   cursor: pointer;
// `;
// function ProductPostForm() {
//     const [imagePreview, setImagePreview] = useState(null);
//     const formData = new FormData();

//     const [product, setProduct] = useState({
//         name: '',
//         description: '',
//         price: '',
//         image: '',
//         category: '',
//         brand: '',
//         inStock: true,
//         rating: '',
//     });
//     const [image, setImage] = useState(null);
//     const handleImageChange = (e) => {
//         const selectedImage = e.target.files[0];
//         setImage(selectedImage);

//         // Show image preview
//         if (selectedImage) {
//             const reader = new FileReader();
//             reader.onload = (event) => {
//                 setImagePreview(event.target.result);
//             };
//             reader.readAsDataURL(selectedImage);
//         } else {
//             setImagePreview(null);
//         }
//     };

//     const handleChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setProduct((prevProduct) => ({
//             ...prevProduct,
//             [name]: type === 'checkbox' ? checked : value,
//         }));
//     };


//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Append fields to FormData
//         Object.keys(product).forEach(key => {
//             if (key !== 'image') { // Skip image here as it's handled separately
//                 formData.append(key, product[key]);
//             }
//         });
//         if (image) {
//             formData.append('image', image); // Ensure you have a backend route to handle file upload
//         }

//         try {
//             const response = await fetch(`${baseUrl}/api/products`, {
//                 method: 'POST',
//                 body: formData, // Send the FormData object
//                 // Don't set Content-Type header, let the browser handle it
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 console.log('Product added successfully:', result);
//                 // Reset form or provide further user feedback
//             } else {
//                 console.error('Failed to add product. Response:', response);
//                 // Handle errors, such as by showing an error message to the user
//             }
//         } catch (error) {
//             console.error('Error submitting product:', error);
//             // Handle errors, such as by showing an error message to the user
//         }
//     };

//     return (
//         <Form onSubmit={handleSubmit}>
//             <Label>Name:
//                 <Input
//                     type="text"
//                     name="name"
//                     value={product.name}
//                     onChange={handleChange}
//                 />
//             </Label>
//             <Label>Description:
//                 <TextArea
//                     name="description"
//                     value={product.description}
//                     onChange={handleChange}
//                 />
//             </Label>
//             <Label>Price:
//                 <Input
//                     type="number"
//                     name="price"
//                     value={product.price}
//                     onChange={handleChange}
//                 />
//             </Label>
//             {/* <Label>Image :
//                 <Input
//                     type="text"
//                     name="image"
//                     value={product.image}
//                     onChange={handleImageChange}
//                 />
//             </Label> */}
//             <FormGroup>
//                 <FileInput
//                     type="file"
//                     id="image"
//                     name="image" 
//                     accept="image/*"
//                     onChange={handleImageChange}
                    
//                 />
//                 <FileInputLabel htmlFor="image">Choose Image</FileInputLabel>
//                 {imagePreview && <PreviewImage src={imagePreview} alt="Selected" />}
//             </FormGroup>
//             <Label>Category:
//                 <Select name="category" value={product.category} onChange={handleChange}>
//                     <option value="">Select a category</option>
//                     <option value="Gateaux">Gateaux</option>
//                     <option value="Clothing">Clothing</option>
//                     {/* Add more categories as needed */}
//                 </Select>
//             </Label>
//             <Label>Brand:
//                 <Input
//                     type="text"
//                     name="brand"
//                     value={product.brand}
//                     onChange={handleChange}
//                 />
//             </Label>
//             <Label>In Stock:
//                 <input
//                     type="checkbox"
//                     name="inStock"
//                     checked={product.inStock}
//                     onChange={handleChange}
//                     style={{ marginLeft: "10px" }}
//                 />
//             </Label>
//             <Label>Rating:
//                 <Input
//                     type="number"
//                     step="0.1"
//                     max="5"
//                     min="0"
//                     name="rating"
//                     value={product.rating}
//                     onChange={handleChange}
//                 />
//             </Label>
//             <Button type="submit">Submit Product</Button>
//         </Form>
//     );
// }

// export default ProductPostForm;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Assuming you have baseUrl defined in your `App.js` or a constants file.
import { baseUrl } from '../App';

// Styled components
const Form = styled.form`
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  position: absolute;
  z-index: 999;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const FormGroup = styled.div`
  margin-bottom: 20px;
`;


const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 10px;
`;

function ProductPostForm({ productId = null }) {
    const [imagePreview, setImagePreview] = useState(null);
    const [product, setProduct] = useState({
        name: '',
        description: '',
        price: '',
        category: '',
        brand: '',
        inStock: true,
        rating: '',
    });
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (productId) {
            fetchProductDetails(productId);
        }
    }, [productId]);

    const fetchProductDetails = async (id) => {
        try {
            const response = await fetch(`${baseUrl}/api/products/${id}`);
            if (response.ok) {
                const prod = await response.json();
                setProduct({
                    name: prod.name,
                    description: prod.description,
                    price: prod.price,
                    category: prod.category,
                    brand: prod.brand,
                    inStock: prod.inStock,
                    rating: prod.rating,
                });
                // Assume the image URL is directly accessible
                setImagePreview(prod.image);
            }
        } catch (error) {
            console.error("Failed to fetch product details", error);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setProduct((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     const updatedProduct = {
    //         name: product.name, // Example: Assuming `product` is your state holding the current product data
    //         description: product.description,
    //         price: product.price,
    //         category: product.category,
    //         brand: product.brand,
    //         inStock: product.inStock,
    //         rating: product.rating,
    //     };
    //     const formData = new FormData();
    //     Object.entries(product).forEach(([key, value]) => formData.append(key, value));
    //     if (image) formData.append('image', image);

    //     const requestOptions = {
    //         method: productId ? 'PUT' : 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(updatedProduct), formData,
    //     };

    //     try {
    //         const url = `${baseUrl}/api/products${productId ? `/${productId}` : ''}`;
    //         const response = await fetch(url, requestOptions);
    //         if (response.ok) {
    //             console.log("Product saved successfully.");
    //         } else {
    //             const errorResponse = await response.json(); // Assuming the server sends back a JSON response
    //             console.error("Failed to save the product:", errorResponse);
    //             console.error('Non-JSON response received:', response.statusText)

    //         }

    //     } catch (error) {
    //         console.error("Failed to save the product due to an error.", error);
    //     }
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const updatedProduct = {
            name: product.name,
            description: product.description,
            price: product.price,
            category: product.category,
            brand: product.brand,
            inStock: product.inStock,
            rating: product.rating,
        };

        const formData = new FormData();
        Object.entries(updatedProduct).forEach(([key, value]) => formData.append(key, value));
        if (image) formData.append('image', image);

        const requestOptions = {
            method: productId ? 'PUT' : 'POST',
            body: formData,
        };

        try {
            const url = `${baseUrl}/api/products${productId ? `/${productId}` : ''}`;
            const response = await fetch(url, requestOptions);

            if (response) {
                console.log("Product saved successfully.", response);
                window.location.reload();
            } else {
                const errorResponse = await response.json(); // Assuming the server sends back a JSON response
                console.error("Failed to save the product:", errorResponse.statusText);
            }
        } catch (error) {
            console.error("Failed to save the product due to an error:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Label>Name:
                <Input
                    type="text"
                    name="name"
                    value={product.name}
                    onChange={handleChange}
                />
            </Label>
            <Label>Description:
                <TextArea
                    name="description"
                    value={product.description}
                    onChange={handleChange}
                />
            </Label>
            <Label>Price:
                <Input
                    type="number"
                    name="price"
                    value={product.price}
                    onChange={handleChange}
                />
            </Label>
            {/* <Label>Image :
                <Input
                    type="text"
                    name="image"
                    value={product.image}
                    onChange={handleImageChange}
                />
            </Label> */}
            <Label>Image:
                <FileInput type="file" id="image" accept="image/*" name="image" onChange={handleImageChange} />
                <FileInputLabel htmlFor="image">Choose Image</FileInputLabel>
                {imagePreview && (
                    <PreviewImage
                        src={
                            imagePreview.startsWith('http') || imagePreview.startsWith('data:image')
                                ? imagePreview
                                : `${baseUrl}/images/${imagePreview}`
                        }
                        alt="Preview"
                    />
                )}

            </Label>
            {/* <FormGroup>
                <FileInput
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}

                />
                <FileInputLabel htmlFor="image">Choose Image</FileInputLabel>
                {imagePreview && <PreviewImage src={imagePreview} alt="Selected" />}
            </FormGroup> */}
            <Label>Category:
                <Select name="category" value={product.category} onChange={handleChange}>
                    <option value="">Select a category</option>
                    <option value="Gateaux">Gateaux</option>
                    <option value="Clothing">Clothing</option>
                    {/* Add more categories as needed */}
                </Select>
            </Label>
            <Label>Brand:
                <Input
                    type="text"
                    name="brand"
                    value={product.brand}
                    onChange={handleChange}
                />
            </Label>
            <Label>In Stock:
                <input
                    type="checkbox"
                    name="inStock"
                    checked={product.inStock}
                    onChange={handleChange}
                    style={{ marginLeft: "10px" }}
                />
            </Label>
            <Label>Rating:
                <Input
                    type="number"
                    step="0.1"
                    max="5"
                    min="0"
                    name="rating"
                    value={product.rating}
                    onChange={handleChange}
                />
            </Label>
            <Button type="submit">Submit Product</Button>
        </Form>
    );
}


export default ProductPostForm;

//             <Button type="submit">Submit Product</Button>
//         </Form >
//     );
// }