import React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
`;

const ItemName = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

const ItemPrice = styled.p`
  font-size: 14px;
`;

const Item = ({ item, onRemove }) => {
    return (
        <ItemContainer>
            <ItemImage src={item.image} alt={item.name} />
            <div>
                <ItemName>{item.name}</ItemName>
                <ItemPrice>Price: ${item.price}</ItemPrice>
            </div>
            {onRemove && <button onClick={() => onRemove(item.id)}>Remove</button>}
        </ItemContainer>
    );
};

export default Item;
