import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  z-index: 999;
`;

const Footer = () => {
    return (
        <FooterWrapper>
            &copy; {new Date().getFullYear()}  HINAFI. All rights reserved.
        </FooterWrapper>
    );
};

export default Footer;
