// import React from 'react';
// import styled from 'styled-components';
// import { FaShoppingCart, FaTrash } from 'react-icons/fa';
// import { useCart } from '../CarteContext';

// const ProductContainer = styled.div`
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   padding: 10px;
//   cursor: pointer;
//   transition: transform 0.3s ease-in-out;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const ProductImage = styled.img`
//   width: 100%;
//   height: 200px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

// const ProductName = styled.h3`
//   font-size: 16px;
//   font-weight: bold;
//   margin: 10px 0;
// `;

// const ProductPrice = styled.p`
//   font-size: 14px;
//   margin-bottom: 10px;
// `;

// const CartIcon = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   font-size: 24px;
//   color: #333;
// `;

// const Product = ({ product }) => {
//     const { cartItems, setCartItems } = useCart();
//     console.log(cartItems)
//     // Check if the product prop is undefined or missing the 'image' property
//     if (!product || !product.image) {
//         return <div>Error: Product data is missing or invalid.</div>;
//     }

//     // Access the properties of the product object
//     // const { id, image, name, price } = product;


//     const addToCart = () => {
//         // Check if the product is already in the cart
//         const existingCartItem = cartItems.find((item) => item.product.id === product.id);

//         if (existingCartItem) {
//             // If the product is already in the cart, increase the quantity
//             setCartItems((prevCartItems) =>
//                 prevCartItems.map((item) =>
//                     item.product.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
//                 )
//             );
//         } else {
//             // If the product is not in the cart, add it with quantity 1
//             setCartItems((prevCartItems) => [...prevCartItems, { product, quantity: 1 }]);
//         }
//     };

//     const removeFromCart = () => {
//         // Remove the product from the cart
//         setCartItems((prevCartItems) =>
//             prevCartItems.filter((item) => item.product.id !== product.id)
//         );
//     };
//     console.log(product)
//     const isInCart = cartItems.some((item) => item.product.id === product.id);
//     return (

//         <ProductContainer>
//             <ProductImage src={product.image} alt={product.name} />
//             <ProductName>{product.name}</ProductName>
//             <ProductPrice>${product.price}</ProductPrice>
//             {isInCart ? (
//                 <CartIcon onClick={removeFromCart}>
//                     <FaTrash />
//                 </CartIcon>
//             ) : (
//                 <CartIcon onClick={addToCart}>
//                     <FaShoppingCart />
//                 </CartIcon>
//             )}
//         </ProductContainer>
//     );
// };

// export default Product;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaShoppingCart, FaTrash } from 'react-icons/fa';
import { useCart } from '../CarteContext';
import axios from 'axios';
import { baseUrl } from '../App';
import { useParams } from 'react-router-dom';

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  max-width: 300px;
  margin: 0 auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ProductDescription = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ProductCategory = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const ProductBrand = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const ProductRating = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const ProductReviews = styled.div`
  font-size: 12px;
`;

const CartIcon = styled.div`
  font-size: 24px;
  color: #333;
`;

const Product = () => {
  const { cartItems, setCartItems } = useCart();
  const [product, setProduct] = useState(null);
  const { productId } = useParams();
  const user = JSON.parse(localStorage.getItem('user'));
  const [loading, setLoading] = useState(true); // Add loading state


  // if (!product || !product.image) {
  //   return <div>Error: Product data is missing or invalid.</div>;
  // }
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/products/${productId}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/cart`);
        setCartItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCartItems();
  }, [setCartItems]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  // const addToCart = (product) => {
  //   // Add the selected product to the cart on the server
  //   axios.post(`${baseUrl}/api/cart`, { product }).then((response) => {
  //     setCartItems(response.data);
  //   });
  // };
  const addToCart = (product) => {
    axios.post(
      `${baseUrl}/api/cart`,
      {
        productId: product.id,
        userId: user.id,
        quantity: 1,
        name: product.name,
        price: product.price,
        image: product.image
      }
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log("Product added to cart:", response.data);
          setCartItems((prevCartItems) => [...prevCartItems, response.data]);
          // You might want to update your cart state here if needed
        }
      })
      .catch((error) => {
        console.log("Error adding product to cart:", error);
      });
  };
  const removeFromCart = (itemId) => {
    // Remove the item from the cart on the server
    axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
      setCartItems(response.data);
    });
  };

  const isInCart = cartItems.some((item) => item.product.id === product.id);

  const cartItemsCount = cartItems.length;


  return (
    <ProductContainer>
      {/* <ProductImage src={baseUrl + '/images/' + product.image} alt={product.name} /> */}
       <ProductName>{product.name}</ProductName>
      <ProductPrice>{product.price} €</ProductPrice>
      <ProductDescription>{product.description}</ProductDescription>
      <ProductCategory>Catégorie: {product.category}</ProductCategory>
      <ProductBrand>Marque: {product.brand}</ProductBrand> 
      {/* {product.reviews.length}  */}
      <ProductRating>Basé sur 10545 avis</ProductRating>
      <ProductReviews>
        VOIR LES AVIS
      </ProductReviews>
      {/* {isInCart ? (
        <CartIcon onClick={removeFromCart}>
          <FaTrash />
        </CartIcon>
      ) : (
        <CartIcon onClick={addToCart}>
          <FaShoppingCart />
        </CartIcon>
      )} */}
      {isInCart ? (

        <CartIcon onClick={() => removeFromCart(product.id)}>
          <FaTrash />
        </CartIcon>
      ) : (
        <CartIcon onClick={() => addToCart(product)}>
          <FaShoppingCart />
          {cartItemsCount}
        </CartIcon>

      )}
      {isInCart ? (
        <button onClick={() => removeFromCart(product.id)}>
          <FaTrash />
          Remove from Cart
        </button>
      ) : (
        <button onClick={() => addToCart(product)}>
          <FaShoppingCart />
          Add to Cart
        </button>
      )}
    </ProductContainer>
  );
};

export default Product;
