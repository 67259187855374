import React from 'react';
import styled, { keyframes } from 'styled-components';
import spirit3 from '../assets/spirit3.png'; // Import your sprite sheet image

const Container = styled.div`
  width: 300px; /* Set the width and height based on the frame dimensions */
  height: 300px;
  background: url(${spirit3}) 0 0 no-repeat; /* Set the initial frame position */
  animation: animateFrames 1s steps(4) infinite; /* Adjust duration and steps based on animation frames */

  @keyframes animateFrames {
    0% { background-position: 0 0; }
    100% { background-position: -400px 0; } /* Adjust based on frame width */
  }
`;

const SpriteSheetAnimation = () => {
    return <Container />;
};

export default SpriteSheetAnimation;
