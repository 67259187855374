// // import React, { createContext, useContext, useReducer, useEffect } from "react";
// // import jwtDecode from "jwt-decode";
// // import Cookies from "js-cookie";

// // const AuthStateContext = createContext();
// // const AuthDispatchContext = createContext();

// // const getToken = () => Cookies.get("token");

// // const setToken = (token) => {
// //   Cookies.set("token", token, { expires: 10 * 365 * 24 * 60 * 60 * 1000 });
// // };

// // const removeToken = () => {
// //   Cookies.remove("token");
// // };

// // let user;
// // const token = getToken();
// // if (token) {
// //   const decodedToken = jwtDecode(token);
// //   const expiresAt = new Date(decodedToken.exp * 10 * 365 * 24 * 60 * 60 * 1000);

// //   if (new Date() > expiresAt) {
// //     removeToken();
// //   } else {
// //     user = decodedToken;
// //   }
// // } else {
// //   console.log("No token found");
// // }

// // const authReducer = (state, action) => {
// //   switch (action.type) {
// //     case "LOGIN":
// //       localStorage.setItem("token", action.payload.token);
// //       setToken(action.payload.token);

// //       return {
// //         ...state,
// //         user: action.payload,
// //         isAuthenticated: true, // Set isAuthenticated to true upon login
// //       };
// //     case "LOGOUT":
// //       removeToken();
// //       localStorage.clear();
// //       return {
// //         ...state,
// //         user: null,
// //         isAuthenticated:false,
// //       };
// //     default:
// //       return state;
// //   }
// // };

// // export const AuthProvider = ({ children }) => {
// //   const initialState = {
// //     user,
// //     isAuthenticated: !!user, // Set initial isAuthenticated based on user presence
// //   };

// //   const [state, dispatch] = useReducer(authReducer, initialState);

// //   useEffect(() => {
// //     const token = getToken();
// //     if (token) {
// //       const decodedToken = jwtDecode(token);
// //       const expiresAt = new Date(decodedToken.exp * 1000);

// //       if (new Date() > expiresAt) {
// //         removeToken();
// //         dispatch({ type: "LOGOUT" });
// //       } else {
// //         dispatch({
// //           type: "LOGIN",
// //           payload: {
// //             token,
// //             ...decodedToken,
// //           },
// //         });
// //       }
// //     }
// //   }, []);

// //   return (
// //     <AuthDispatchContext.Provider value={dispatch}>
// //       <AuthStateContext.Provider value={state}>
// //         {children}
// //       </AuthStateContext.Provider>
// //     </AuthDispatchContext.Provider>
// //   );
// // };

// // export const useAuthState = () => useContext(AuthStateContext);
// // export const useAuthDispatch = () => useContext(AuthDispatchContext);
// // AuthContext.js
// // AuthContext.js
// import React, { createContext, useContext, useReducer, useEffect } from "react";
// import jwtDecode from "jwt-decode";
// import Cookies from "js-cookie";

// // Create context objects for state and dispatch
// const AuthStateContext = createContext();
// const AuthDispatchContext = createContext();

// // Helper functions for cookie management
// const getToken = () => Cookies.get("token");
// const setToken = (token) => Cookies.set("token", token, { expires: 365 }); // Simpler expiry
// const removeToken = () => Cookies.remove("token");

// // Attempt to get and validate the token at start-up
// let user = null;
// const token = getToken();
// if (token) {
//   const decodedToken = jwtDecode(token);
//   const expiresAt = new Date(decodedToken.exp * 1000);
//   if (new Date() < expiresAt) {
//     user = decodedToken;
//   } else {
//     removeToken();
//   }
// }

// // Reducer to manage auth state
// const authReducer = (state, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       setToken(action.payload.token);
//       return { ...state, user: jwtDecode(action.payload.token), isAuthenticated: true };
//     case "LOGOUT":
//       removeToken();
//       return { ...state, user: null, isAuthenticated: false };
//     default:
//       return state;
//   }
// };

// // AuthProvider component to wrap your app and provide auth state
// export const AuthProvider = ({ children }) => {
//   const initialState = { user, isAuthenticated: !!user };
//   const [state, dispatch] = useReducer(authReducer, initialState);

//   // Effect to automatically log out if the token expires
//   useEffect(() => {
//     const checkTokenExpiration = () => {
//       if (state.isAuthenticated && user) {
//         const now = new Date();
//         if (now >= new Date(user.exp * 1000)) {
//           dispatch({ type: "LOGOUT" });
//         }
//       }
//     };
//     const interval = setInterval(checkTokenExpiration, 60000); // Check every minute
//     return () => clearInterval(interval);
//   }, [state.isAuthenticated]);

//   return (
//     <AuthDispatchContext.Provider value={dispatch}>
//       <AuthStateContext.Provider value={state}>
//         {children}
//       </AuthStateContext.Provider>
//     </AuthDispatchContext.Provider>
//   );
// };

// // Hooks for easy context usage
// export const useAuthState = () => useContext(AuthStateContext);
// export const useAuthDispatch = () => useContext(AuthDispatchContext);

// // useAuth.js
// // export const useAuth = () => {
// //   const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
// //   return { isAuthenticated };
// // };
import React, { createContext, useContext, useReducer, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const getToken = () => localStorage.getItem("token");

const setToken = (token) => {
  Cookies.set("token", token, { expires: 10 * 365 * 24 * 60 * 60 * 1000 });
};

const removeToken = () => {
  Cookies.remove("token");
};

let user;
const token = getToken();
if (token) {
  const decodedToken = jwtDecode(token);
  const expiresAt = new Date(decodedToken.exp * 10 * 365 * 24 * 60 * 60 * 1000);

  if (new Date() > expiresAt) {
    removeToken();
  } else {
    user = decodedToken;
  }
} else {
  console.log("No token found");
}

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      setToken(action.payload.token);

      return {
        ...state,
        user: action.payload,
        isAuthenticated: true, // Set isAuthenticated to true upon login
      };
    case "LOGOUT":
      removeToken();
      localStorage.clear();
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const initialState = {
    user,
    isAuthenticated: !!user, // Set initial isAuthenticated based on user presence
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const token = getToken();
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiresAt = new Date(decodedToken.exp * 1000);

      if (new Date() > expiresAt) {
        removeToken();
        dispatch({ type: "LOGOUT" });
      } else {
        dispatch({
          type: "LOGIN",
          payload: {
            token,
            ...decodedToken,
          },
        });
      }
    }
  }, []);

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);

