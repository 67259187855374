// import React, { useState, useRef, useEffect, useContext } from 'react';

// import styled from 'styled-components';
// import TopBar from '../components/TopBar';
// import Footer from '../components/Footer';
// import img7 from '../assets/img666.png';
// import img2 from '../assets/img23.jpg';
// import img3 from '../assets/img24.jpg';
// import img4 from '../assets/img99.png';
// import imgp8 from '../assets/imgp8.PNG';
// import imgp9 from '../assets/imgp9.PNG';
// import imgp10 from '../assets/imgp10.PNG';
// import ImageSlider from '../components/ImageSlider';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { baseUrl } from '../App';
// import { ProductsContext } from '../ProductsContext';
// import { useCart } from '../CarteContext';
// import { useAuthState } from './../context/AuthContext';
// import ProductPostForm from '../components/ProductPostForm';
// import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const slides = [
//     imgp10,

//     img7, img2,
//     img3,
//     imgp9,
//     img7, imgp9, img2,
//     img3,
//     imgp10,
//     img4,
//     img7, imgp8,


//     img4,
//     img7, imgp8,


// ];

// const SquareContainer = styled.div`
//   /* width: 600px;
//   height: 600px; */
//   /* border: 1px solid #ccc; */
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   grid-template-rows: repeat(2, 1fr);
//   gap: 50px;
//   margin: 0 0 20% 0 ;
//   margin-top: 25px;
//     object-fit: contain;
//     padding: 2%;
// `;

// const SquareImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   cursor: pointer;
//     border-radius: 15%;
//   &:hover {
//    /* transform: scale(1.5) translateY(-10px); */
//     /* width: 50%;
//   height: 50%; */
//   object-fit: cover;
//    border-radius: 5%; 
  
//   }
// `;
// const HomeContainer = styled.div`
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   /* align-items: center; */
//    @media (max-width: 768px) {
//   width: min-content;
//   display: grid;
    
//   }

// `;

// const DockContainer = styled.div`
//   /* position: fixed; */
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: #f0f0f0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 50px 0;
//   z-index: 999;
// `;

// const DockIcon = styled.img`
//   width: 50px;
//   height: 50px;
//   object-fit: contain;
//   margin: 0 10px;
//   cursor: pointer;
//   transition: transform 0.3s;

//   &:hover {
//    transform: scale(1.5) translateY(-10px);
  
//   }
// `;

// const dockNames = [
//     'Finder',
//     'Siri',
//     'LaunchPad',
//     'Contacts',
//     'Notes',
//     'inder',
//     'iri',
//     'aunchPad',
//     'ontacts',
//     'otes',
//     'unchPad',
//     'ntacts',
//     'tes',

//     // Add more names here
// ];
// const DockIconName = styled.div`
//   position: absolute;

//   top: -70px;
//   background: rgba(0, 0, 0, 0.5);
//   color: rgba(255, 255, 255, 0.9);
//   height: 20px;
//   padding: 10px 15px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;
//    /* visibility: hidden;  */
// `;
// const ProductList = styled.div`
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   grid-gap: 20px;
//   margin-top: 20px;
// `;

// const ProductCard = styled.div`

//   border: 1px solid #E9B529;
//   border-radius: 5px;
//   padding: 10px;
//   cursor: pointer;
//   transition: transform 0.3s ease-in-out;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const ProductImage = styled.img`
//   width: 100%;
//   height: 200px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

// const ProductName = styled.h3`
//   font-size: 16px;
//   font-weight: bold;
//   margin: 10px 0;
// `;

// const ProductPrice = styled.p`
//   font-size: 14px;
//   margin-bottom: 10px;
// `;
// // const CartContainer = styled.div`
// //   margin-top: 20px;
// //   width: 100%;
// //   max-width: 600px;
// //   border: 1px solid #ccc;
// //   border-radius: 5px;
// //   padding: 10px;
// //    margin-bottom: 50px;
// // `;
// const NewsWrapper = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 20px;
//   /* background-color: #f5f5f5; */
//   padding: 80px 0 0 0;
// `;

// const CartContainer = styled.div`
//    /* flex-direction: column; */
// /* display: ruby; */
//     text-align-last: center;
//   margin: 20px auto;
//   padding: 20px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   align-self: center;
// `;
// const CartTitle = styled.h2`
//   font-size: 18px;
//   font-weight: bold;
//   margin-bottom: 10px;
// `;

// const CartItem = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 10px;
// `;

// const CartItemInfo = styled.div`
//   flex-grow: 1;
//   margin-left: 10px;
// `;

// const CartItemImage = styled.img`
//   width: 100%;
//   height: 100px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

// const CartItemName = styled.h3`
//   font-size: 14px;
//   font-weight: bold;
//   margin-bottom: 5px;
// `;

// const CartItemPrice = styled.p`
//   font-size: 12px;
//   margin-bottom: 5px;
// `;

// const RemoveButton = styled.button`
//   font-size: 12px;
//   background-color: #ff5851;
//   color: #000;
//   border: none;
//   border-radius: 5px;
//   padding: 5px 10px;
//   cursor: pointer;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #d4433f;
//   }
// `;
// const Loading = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   color: #000;
//   font-family: arial, sans-serif;
// `;

// const Loader = styled.div`
//   width: 60px;
// `;

// const LoaderWheel = styled.div`
//   animation: spin 1s infinite linear;
//   border: 2px solid rgba(30, 30, 30, 0.5);
//   border-left: 4px solid #fff;
//   border-radius: 50%;
//   height: 50px;
//   margin-bottom: 10px;
//   width: 50px;
// `;
// const EditIcon = styled(FontAwesomeIcon)`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;


//   background-color: pink;
// `;
// const LoaderText = styled.div`
//   &:after {
//     content: 'Loading';
//     animation: load 2s linear infinite;
//   }
// `;


// const Home = () => {
//     const [lightboxIndex, setLightboxIndex] = useState(null);
//   const [lightbox, setLightbox] = useState(false);
//   const [showAddForm, setShowAddForm] = useState(false);
//   const [isEditModalVisible, setIsEditModalVisible] = useState(false);
//   const [selectedArticleId, setSelectedArticleId] = useState(null);
//   const [products, setProducts] = useContext(ProductsContext);
//   const [imagePreview, setImagePreview] = useState();

// const handleImageChange = (e) => {
//   const file = e.target.files[0];
//   if (file) {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setImagePreview(reader.result);
//     };
//     reader.readAsDataURL(file);
//   }
// };

// // In your form
// {/* <input type="file" onChange={handleImageChange} />
// <img src={imagePreview} alt="Preview" /> */}


//     const icons = [
//         img7, img2,
//         img3,
//         img4,
//         imgp8,
//         imgp9,
//         imgp10,
//     ];
//     const dockIcons = [
//         "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853ff3bafbac60495771_siri.png",
//         "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853743597518c528b9b3_contacts.png",
//         img7, img2,
//         img3,
//         img4,
//         imgp8,
//         imgp9,
//         imgp10,
//         "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f708537f18e2cb27247c904_facetime.png",
//         "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853a55558a68e192ee08_messages.png",
//         "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853943597517f128b9b4_launchpad.png",
//         "https://findicons.com/files/icons/569/longhorn_objects/128/trash.png"
//     ];

//     const handleImageClick = (index) => {
//         setLightboxIndex(index);
//         setLightbox(true);
//     };

//     const handleCloseLightbox = () => {
//         setLightboxIndex(null);
//         setLightbox(false);
//     };
//     /*** */
//     // const iconsRef = useRef([]);

//     const handleMouseEnter = (index) => {
//         const icons = iconsRef.current;

//         icons[index].style.transform = 'scale(1.5) translateY(-10px)';

//         const previous = index - 1;
//         const previous1 = index - 2;
//         const next = index + 1;
//         const next2 = index + 2;

//         if (previous >= 0) icons[previous].style.transform = 'scale(1.2) translateY(-6px)';
//         if (previous1 >= 0) icons[previous1].style.transform = 'scale(1.1)';
//         if (next < icons.length) icons[next].style.transform = 'scale(1.2) translateY(-6px)';
//         if (next2 < icons.length) icons[next2].style.transform = 'scale(1.1)';
//         iconsRef.current[index].style.transform = 'scale(1.5) translateY(-10px)';
//         setShowIconName(true);
//         setIconName(dockNames[index]);
//     };


//     const handleMouseLeave = () => {
//         const icons = iconsRef.current;

//         icons.forEach((icon) => {
//             icon.style.transform = 'scale(1) translateY(0)';
//         });
//     };
//     const iconsRef = useRef(Array(dockIcons.length).fill(null));
//     const [showIconName, setShowIconName] = useState(false);
//     const [iconName, setIconName] = useState('');
//     const token = localStorage.getItem('token');
//     const user = JSON.parse(localStorage.getItem('user'));
//     const { cartItems, setCartItems } = useCart();
//   const navigate = useNavigate();
//   const { isAuthenticated } = useAuthState(); 
//     useEffect(() => {

//         // Fetch cart items from the server
//         axios.get(`${baseUrl}/api/cart`).then((response) => {
//             setCartItems(response.data);
//             // setCartItemsCount(response.data.length); // Update the cart items count
//         });
//     }, [setCartItems]);
//     const headers = {
//         Accept: 'application/json',
//         Authorization: `Bearer ${token}`,
//     };

//   const removeFromCart = (itemId) => {
//     console.log('Removing item with ID:', itemId);

//     // Remove the item from the cart on the server
//     axios.delete(`${baseUrl}/api/cart/${itemId}`)
//       .then(() => {
//         console.log('Item removed successfully');

//         // Attempt to update cart items state by filtering out the removed item
//         setCartItems((prevCartItems) => {
//           console.log('Previous cart items:', prevCartItems);

//           const filteredItems = prevCartItems.filter((item) => {
//             // Convert both ids to the same type (number) before comparison
//             const itemToRemove = Number(item.id);
//             const itemIdNumber = Number(itemId);
//             console.log(`Checking if item ${itemToRemove} equals ${itemIdNumber}:`, itemToRemove === itemIdNumber);

//             // Keep the item if its id does not match the itemId
//             return itemToRemove !== itemIdNumber;
//           });

//           console.log('Filtered cart items:', filteredItems);
//           return filteredItems;
//         });
//       })
//       .catch((error) => {
//         console.error("Error removing product from cart:", error);
//       });
//   };
//     // console.log(cartItemsCount)
//     // Calculate the cart items count
//     const cartItemsCount = cartItems.length;
//     // Wait for the data to be loaded before rendering the child component
//     // if (products.length === 0) {
//     //     return <Loading>
//     //         <Loader>
//     //             <LoaderWheel />
//     //         </Loader>
//     //         <LoaderText>Loading...</LoaderText>
//     //     </Loading>;
//     // }
//     const cartArray = Object.values(cartItems)


//   const addToCart = (product) => {
//     if (!isAuthenticated) {
//       // Redirect to login page if not authenticated
//       navigate('/login');
//       return;
//     }

//     if (!product) {
//       console.error('No product to add');
//       return;
//     }

//     // Proceed with adding to cart if the product is defined and user is authenticated
//     axios.post(
//       `${baseUrl}/api/cart`,
//       {
//         productId: product.id,
//         userId: user.id, // Assuming user object has an id property
//         quantity: 1,
//         name: product.name,
//         price: product.price,
//         image: product.image
//       }
//     )
//       .then((response) => {
//         if (response.data.error) {
//           console.log(response.data.error);
//         } else {
//           console.log("Product added to cart:", response.data);
//           // You might want to update your cart state here if needed
//           setCartItems((prevCartItems) => [...prevCartItems, response.data]);
//           // navigate('/cart');
//         }
//       })
//       .catch((error) => {
//         console.log("Error adding product to cart:", error);
//       });
//   };
//   // Function to edit a product
//   const handleEdit = (productId) => {
//     setSelectedArticleId(productId);
//     setIsEditModalVisible(true);
//   };

//   // Function to delete a product
//   const handleDelete = (productId) => {
//     axios.delete(`${baseUrl}/api/products/${productId}`)
//       .then(() => {
//         // On successful delete, remove the product from the local state to update the UI
//         setProducts(currentProducts => currentProducts.filter(product => product.id !== productId));
//       })
//       .catch(error => {
//         console.error('Error deleting product:', error);
//         // Handle error (e.g., show a notification)
//       });
//   };

//   // useEffect to load products from backend
//   useEffect(() => {
//     // Fetch products logic (assuming you're fetching products on component mount)
//   }, []);

//   // const safeProducts = Array.isArray(products) ? products : [];
//   console.log(products)
//     return (
//         <>
//             <HomeContainer>

//           <TopBar cartItemsCount={cartItemsCount} />

//                 <ImageSlider />

//           <CartContainer>
//             <h4>Assortiments</h4>
          

//             <h1>Découvrez nos assortiments de pâtisseries orientales</h1>
//             <NewsWrapper>

//               {showAddForm && <ProductPostForm />}
//               {isEditModalVisible && <ProductPostForm productId={selectedArticleId} />}

//               <button onClick={() => setShowAddForm(true)}>Add New product</button>



//             </NewsWrapper>
//             <SquareContainer>
     
                  
              
//               {products.map((product) => (
//                 <div key={product.id}>
//                   <EditIcon icon={faEdit} onClick={() => handleEdit(product.id)} >edit</EditIcon>

//                             <Link to={`/product/${product.id}`}>
//                               <ProductCard>

//                                         <ProductImage src={baseUrl + '/images/' + product.image} alt={product.name} />
//                                         <ProductName>{product.name}</ProductName>
//                                         <ProductPrice>${product.price}</ProductPrice>
//                                     </ProductCard>
//                             </Link>

//                             <button onClick={() => addToCart(product)}>Add to Cart</button> 

//                             <button onClick={() => handleEdit(product.id)}>Edit</button>
                            
//                             <button onClick={() => handleDelete(product.id)}>Delete</button>

//                             </div>
//                         ))}

//                 </SquareContainer>
//                     <ProductList>
     
   

//                     </ProductList>
//                 {lightbox && lightboxIndex !== null && (
//                     <div onClick={handleCloseLightbox} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.8)', zIndex: 999 }}>
//                         <div style={{ maxWidth: '200px', padding: '20px', textAlign: 'center', margin: '100px auto', background: '#fff', borderRadius: '5px' }}>
//                             <img src={slides[lightboxIndex]} alt={`Slide ${lightboxIndex + 1}`} style={{ width: '100%', height: 'auto', border: '1px solid #fff', borderRadius: '5px', marginBottom: '10px' }} />
//                             <div style={{ fontSize: '50px', color: 'red', marginBottom: '10px' }}>Image Info Here</div>
//                             <button onClick={handleCloseLightbox} style={{ padding: '8px 16px', background: '#ccc', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Close</button>
//                         </div>
//                     </div>
//                     )}
//                 </CartContainer>
//                 <CartContainer>
//                     <CartTitle>Cart</CartTitle>
//             {cartArray.map((item) => (

//               <CartItem key={item.id}>

//                             <CartItemImage src={baseUrl + '/images/' + item.image} alt={item.name} />
//                             <CartItemInfo>
//                                 <CartItemName>{item.id}</CartItemName>
//                                 <CartItemPrice>{item.price}</CartItemPrice>
//                                 <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
//                             </CartItemInfo>
//                         </CartItem>
//                     ))}
//                 </CartContainer>
//                 <div className="dock-icons-container">
//                     <DockContainer>
//                         {dockIcons.map((iconSrc, index) => (
//                             <div key={index} style={{ position: 'relative' }}>
//                                 <DockIcon
//                                     key={index}
//                                     src={iconSrc}
//                                     alt={`Icon ${index + 1}`}
//                                     ref={(el) => (iconsRef.current[index] = el)}
//                                     onMouseEnter={() => handleMouseEnter(index)}
//                                     onMouseLeave={handleMouseLeave}
//                                 />
//                                 {showIconName && iconName === dockNames[index] && <DockIconName>{dockNames[index]}</DockIconName>}
//                             </div>
//                         ))}

//                     </DockContainer>
//                 </div>
//             </HomeContainer>

//             <Footer /></>

//     );
// };

// export default Home;
import React, { useState, useRef, useEffect, useContext } from 'react';

import styled from 'styled-components';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import img7 from '../assets/img666.png';
import img2 from '../assets/img23.jpg';
import img3 from '../assets/img24.jpg';
import img4 from '../assets/img99.png';
import imgp8 from '../assets/imgp8.PNG';
import imgp9 from '../assets/imgp9.PNG';
import imgp10 from '../assets/imgp10.PNG';
import ImageSlider from '../components/ImageSlider';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../App';
import { ProductsContext } from '../ProductsContext';
import { useCart } from '../CarteContext';
import { useAuthState } from './../context/AuthContext';
import ProductPostForm from '../components/ProductPostForm';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import MenuDots from '../components/menuBurger/MenuDots';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useState, useEffect } from "react";
// import { useRef } from "react";
// import { useAuthDispatch, useAuthState } from "../../context/auth";

// const { user } = useAuthState();
// const { state, dispatch } = React.useContext(AuthContext);



// { showAddForm && <ProductPostForm /> }
// { isEditModalVisible && <ProductPostForm productId={selectedArticleId} /> }

// <button onClick={() => setShowAddForm(true)}>Add New product</button>
const DropdownWrapper = styled.div`
  position: relative;
`;


const ModelWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute; 
  background: transparent;
  border-radius: 1rem; 
  padding: 13px 2rem; 
  z-index: 30;
  box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
`;

const ModalContent = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ModalItem = styled.li`
  margin-bottom: 1rem;
`;

const ModalButton = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  border: none;
  background: #d2c7c78c;
  text-transform: capitalize;
  transition: height 0.3s ease-in-out 0s, opacity 0.1s ease 0.02s;
`;
const slides = [
  imgp10,

  img7, img2,
  img3,
  imgp9,
  img7, imgp9, img2,
  img3,
  imgp10,
  img4,
  img7, imgp8,


  img4,
  img7, imgp8,


];

const SquareContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 50px;
  margin: 0 0 20% 0;
  margin-top: 25px;
  object-fit: contain;
  padding: 2%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust columns for smaller screens */
    grid-template-rows: repeat(2, 1fr); /* Adjust rows for smaller screens */
    gap: 20px; /* Reduce gap for smaller screens */
    margin: 0; /* Remove margin for smaller screens */
  }
`;

const HomeContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  @media (max-width: 768px) {
    width: min-content;
    display: grid;
    justify-content: center; /* Center grid items horizontally */
    align-items: center; /* Center grid items vertically */
  }
`;

const SquareImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
    border-radius: 15%;
  &:hover {
   /* transform: scale(1.5) translateY(-10px); */
    /* width: 50%;
  height: 50%; */
  object-fit: cover;
   border-radius: 5%; 
  
  }
`;
// const HomeContainer = styled.div`
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   /* align-items: center; */
//    @media (max-width: 768px) {
//   width: min-content;
//   display: grid;

//   }

// `;

const DockContainer = styled.div`
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  z-index: 999;
`;

const DockIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
   transform: scale(1.5) translateY(-10px);
  
  }
`;

const dockNames = [
  'Finder',
  'Siri',
  'LaunchPad',
  'Contacts',
  'Notes',
  'inder',
  'iri',
  'aunchPad',
  'ontacts',
  'otes',
  'unchPad',
  'ntacts',
  'tes',

  // Add more names here
];
const DockIconName = styled.div`
  position: absolute;

  top: -70px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.9);
  height: 20px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
   /* visibility: hidden;  */
`;
const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
`;

const ProductCard = styled.div`

  border: 1px solid #E9B529;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  /* &:hover {
    transform: scale(1.05);
  } */
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;
// const CartContainer = styled.div`
//   margin-top: 20px;
//   width: 100%;
//   max-width: 600px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   padding: 10px;
//    margin-bottom: 50px;
// `;
const NewsWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  /* background-color: #f5f5f5; */
  padding: 80px 0 0 0;
`;

const CartContainer = styled.div`
   /* flex-direction: column; */
/* display: ruby; */
    text-align-last: center;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  align-self: center;
`;
const CartTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CartItemInfo = styled.div`
  flex-grow: 1;
  margin-left: 10px;
`;

const CartItemImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
`;

const CartItemName = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CartItemPrice = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  font-size: 12px;
  background-color: #ff5851;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d4433f;
  }
`;
const TreeDot = styled.button`
    font-size: 35px;
    z-index: 966;
    background-color: #12281b99;
    color: #ff0000;
    border: none;
    border-radius: 4px;
    padding: 0px 0px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: auto;
    position: absolute;
`;
const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: arial, sans-serif;
`;

const Loader = styled.div`
  width: 60px;
`;

const LoaderWheel = styled.div`
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
`;
const EditIcon = styled(FontAwesomeIcon)`
  top: 10px;
  right: 10px;
  cursor: pointer;


  background-color: pink;
`;
const LoaderText = styled.div`
  &:after {
    content: 'Loading';
    animation: load 2s linear infinite;
  }
`;


const Home = () => {
  const [lightboxIndex, setLightboxIndex] = useState(null);
  const [lightbox, setLightbox] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [products, setProducts] = useContext(ProductsContext);
  const [imagePreview, setImagePreview] = useState();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // In your form
  {/* <input type="file" onChange={handleImageChange} />
<img src={imagePreview} alt="Preview" /> */}


  const icons = [
    img7, img2,
    img3,
    img4,
    imgp8,
    imgp9,
    imgp10,
  ];
  const dockIcons = [
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853ff3bafbac60495771_siri.png",
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853743597518c528b9b3_contacts.png",
    img7, img2,
    img3,
    img4,
    imgp8,
    imgp9,
    imgp10,
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f708537f18e2cb27247c904_facetime.png",
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853a55558a68e192ee08_messages.png",
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853943597517f128b9b4_launchpad.png",
    "https://findicons.com/files/icons/569/longhorn_objects/128/trash.png"
  ];

  const handleImageClick = (index) => {
    setLightboxIndex(index);
    setLightbox(true);
  };

  const handleCloseLightbox = () => {
    setLightboxIndex(null);
    setLightbox(false);
  };
  /*** */
  // const iconsRef = useRef([]);

  const handleMouseEnter = (index) => {
    const icons = iconsRef.current;

    icons[index].style.transform = 'scale(1.5) translateY(-10px)';

    const previous = index - 1;
    const previous1 = index - 2;
    const next = index + 1;
    const next2 = index + 2;

    if (previous >= 0) icons[previous].style.transform = 'scale(1.2) translateY(-6px)';
    if (previous1 >= 0) icons[previous1].style.transform = 'scale(1.1)';
    if (next < icons.length) icons[next].style.transform = 'scale(1.2) translateY(-6px)';
    if (next2 < icons.length) icons[next2].style.transform = 'scale(1.1)';
    iconsRef.current[index].style.transform = 'scale(1.5) translateY(-10px)';
    setShowIconName(true);
    setIconName(dockNames[index]);
  };


  const handleMouseLeave = () => {
    const icons = iconsRef.current;

    icons.forEach((icon) => {
      icon.style.transform = 'scale(1) translateY(0)';
    });
  };
  const iconsRef = useRef(Array(dockIcons.length).fill(null));
  const [showIconName, setShowIconName] = useState(false);
  const [iconName, setIconName] = useState('');
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  const { cartItems, setCartItems } = useCart();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthState();
  useEffect(() => {

    // Fetch cart items from the server
    axios.get(`${baseUrl}/api/cart`).then((response) => {
      setCartItems(response.data);
      // setCartItemsCount(response.data.length); // Update the cart items count
    });
  }, [setCartItems]);
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const removeFromCart = (itemId) => {
    console.log('Removing item with ID:', itemId);

    // Remove the item from the cart on the server
    axios.delete(`${baseUrl}/api/cart/${itemId}`)
      .then(() => {
        console.log('Item removed successfully');

        // Attempt to update cart items state by filtering out the removed item
        setCartItems((prevCartItems) => {
          console.log('Previous cart items:', prevCartItems);

          const filteredItems = prevCartItems.filter((item) => {
            // Convert both ids to the same type (number) before comparison
            const itemToRemove = Number(item.id);
            const itemIdNumber = Number(itemId);
            console.log(`Checking if item ${itemToRemove} equals ${itemIdNumber}:`, itemToRemove === itemIdNumber);

            // Keep the item if its id does not match the itemId
            return itemToRemove !== itemIdNumber;
          });

          console.log('Filtered cart items:', filteredItems);
          return filteredItems;
        });
      })
      .catch((error) => {
        console.error("Error removing product from cart:", error);
      });
  };
  // console.log(cartItemsCount)
  // Calculate the cart items count
  const cartItemsCount = cartItems.length;
  // Wait for the data to be loaded before rendering the child component
  // if (products.length === 0) {
  //     return <Loading>
  //         <Loader>
  //             <LoaderWheel />
  //         </Loader>
  //         <LoaderText>Loading...</LoaderText>
  //     </Loading>;
  // }
  const cartArray = Object.values(cartItems)


  const addToCart = (product) => {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      navigate('/login');
      return;
    }

    if (!product) {
      console.error('No product to add');
      return;
    }

    // Proceed with adding to cart if the product is defined and user is authenticated
    axios.post(
      `${baseUrl}/api/cart`,
      {
        productId: product.id,
        userId: user.id, // Assuming user object has an id property
        quantity: 1,
        name: product.name,
        price: product.price,
        image: product.image
      }
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log("Product added to cart:", response.data);
          // You might want to update your cart state here if needed
          setCartItems((prevCartItems) => [...prevCartItems, response.data]);
          // navigate('/cart');
        }
      })
      .catch((error) => {
        console.log("Error adding product to cart:", error);
      });
  };
  // Function to edit a product
  const handleEdit = (productId) => {
    setSelectedArticleId(productId);
    setIsEditModalVisible(true);
  };

  // Function to delete a product
  const handleDelete = (productId) => {
    axios.delete(`${baseUrl}/api/products/${productId}`)
      .then(() => {
        // On successful delete, remove the product from the local state to update the UI
        setProducts(currentProducts => currentProducts.filter(product => product.id !== productId));
      })
      .catch(error => {
        console.error('Error deleting product:', error);
        // Handle error (e.g., show a notification)
      });
  };

  // useEffect to load products from backend
  useEffect(() => {
    // Fetch products logic (assuming you're fetching products on component mount)
  }, []);
  const dropdownWrapper = useRef(null);
  // const safeProducts = Array.isArray(products) ? products : [];
  const MenuDots = ({ productId, handleEdit, handleDelete }) => {
    const [open, setOpen] = useState(false);
    const dropdownwrapper = useRef(null);
    

    const handleClickOutside = (event) => {
      if (dropdownwrapper.current && !dropdownwrapper.current.contains(event.target)) {
        setOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      // <div className="dropdownwrapper" ref={dropdownwrapper}>
      //   <TreeDot onClick={() => setOpen(!open)}>⋮</TreeDot> 
      //   {open && (
      //     <div className="modelwrapper">
      //       <ul className="modal__content modalx">
      //         <li>
      //           <button onClick={() => handleEdit(productId)}>Edit</button>
      //         </li>
      //         <li>
      //           <button onClick={() => handleDelete(productId)}>Delete</button>
      //         </li>
      //       </ul>
      //     </div>
      //   )}
      // </div>
      <DropdownWrapper ref={dropdownWrapper}>
        <TreeDot onClick={() => setOpen(!open)}>⋮</TreeDot>
        {open && (
          <ModelWrapper>
            <ModalContent>
              <ModalItem>
                <ModalButton onClick={() => handleEdit(productId)}>Edit</ModalButton>
              </ModalItem>
              <ModalItem>
                <ModalButton onClick={() => handleDelete(productId)}>Delete</ModalButton>
              </ModalItem>
            </ModalContent>
          </ModelWrapper>
        )}
      </DropdownWrapper>
    );
  };
  return (
    <>
      <HomeContainer>

        <TopBar cartItemsCount={cartItemsCount} />

        <ImageSlider />
        {/* <button onClick={() => setShowAddForm(true)}>Add New product</button> */}

        <CartContainer>
          <h4>Assortiments</h4>


          <h1>Découvrez nos assortiments de pâtisseries orientales</h1>
          <NewsWrapper>

            {showAddForm && <ProductPostForm />}
            {isEditModalVisible && <ProductPostForm productId={selectedArticleId} />}




          </NewsWrapper>
          
          <SquareContainer>



            {products.map((product) => (
              <div key={product.id}>
                {/* <EditIcon icon={faEdit} onClick={() => handleEdit(product.id)} >edit</EditIcon> */}
                <MenuDots productId={product.id} handleEdit={handleEdit} handleDelete={handleDelete} />


                <Link to={`/product/${product.id}`}>

                  <ProductCard>

                    <ProductImage src={baseUrl + '/images/' + product.image} alt={product.name} />
                    <ProductName>{product.name}</ProductName>
                    <ProductPrice>${product.price}</ProductPrice>
                  </ProductCard>
                </Link>

                <button onClick={() => addToCart(product)}>Add to Cart</button>

                {/* <button onClick={() => handleEdit(product.id)}>Edit</button>
                            
                            <button onClick={() => handleDelete(product.id)}>Delete</button> */}

              </div>
            ))}

          </SquareContainer>
          <ProductList>



          </ProductList>
          {lightbox && lightboxIndex !== null && (
            <div onClick={handleCloseLightbox} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.8)', zIndex: 999 }}>
              <div style={{ maxWidth: '200px', padding: '20px', textAlign: 'center', margin: '100px auto', background: '#fff', borderRadius: '5px' }}>
                <img src={slides[lightboxIndex]} alt={`Slide ${lightboxIndex + 1}`} style={{ width: '100%', height: 'auto', border: '1px solid #fff', borderRadius: '5px', marginBottom: '10px' }} />
                <div style={{ fontSize: '50px', color: 'red', marginBottom: '10px' }}>Image Info Here</div>
                <button onClick={handleCloseLightbox} style={{ padding: '8px 16px', background: '#ccc', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Close</button>
              </div>
            </div>
          )}
        </CartContainer>
        <CartContainer>
          <CartTitle>Cart</CartTitle>
          {cartArray.map((item) => (

            <CartItem key={item.id}>

              <CartItemImage src={baseUrl + '/images/' + item.image} alt={item.name} />
              <CartItemInfo>
                <CartItemName>{item.id}</CartItemName>
                <CartItemPrice>{item.price}</CartItemPrice>
                <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
              </CartItemInfo>
            </CartItem>
          ))}
        </CartContainer>
        <div className="dock-icons-container">
          <DockContainer>
            {dockIcons.map((iconSrc, index) => (
              <div key={index} style={{ position: 'relative' }}>
                <DockIcon
                  key={index}
                  src={iconSrc}
                  alt={`Icon ${index + 1}`}
                  ref={(el) => (iconsRef.current[index] = el)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                />
                {showIconName && iconName === dockNames[index] && <DockIconName>{dockNames[index]}</DockIconName>}
              </div>
            ))}

          </DockContainer>
        </div>
      </HomeContainer>

      <Footer /></>

  );
};

export default Home;