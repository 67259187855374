
import React, { useState } from 'react';
import Item from './Item';
import styled from 'styled-components';
import TopBar from './TopBar';
import { useCart } from '../CarteContext';
import { useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../App';

const ShoppingCartContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ShoppingCartHeading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;
const CartButton = styled.button.withConfig({
    shouldForwardProp: (prop) => prop !== 'primary',
})`
  background-color: ${({ primary }) => (primary ? '#007bff' : '#28a745')};
  color: #fff;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
const CartLabel = styled.label`
  display: block;
  margin-top: 10px;
`;

const CartContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CartTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CartItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
  width: 100%;
`;

const CartItemInfo = styled.div`
  flex: 1;
`;

const CartItemName = styled.h3`
  font-size: 16px;
  margin-bottom: 5px;
`;

const CartItemPrice = styled.p`
  font-size: 14px;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
`;
/** */
const AddItemSection = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
`;

const PaymentOptionsSection = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
`;

const PaymentOptionLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`;

const CartButtonWrapper = styled.div`
  margin-top: 20px;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;



    // const ShoppingCart = () => {
    //     const { cartItems, setCartItems } = useCart();
    //     const [newItem, setNewItem] = useState({});
    //     const [paymentOption, setPaymentOption] = useState('');
    //     const [loading, setLoading] = useState(true); 
    //     useEffect(() => {
    //         updateLocalStorage(cartItems);
    //     }, [cartItems]);
    //     useEffect(() => {
    //         loadCartItemsFromLocalStorage();
    //     }, []);
    //     useEffect(() => {
    //         updateLocalStorage(cartItems);
    //     }, [cartItems]);
    //     const handleAddItem = () => {
    //         setCartItems([...cartItems, newItem]);
    //         setNewItem({});
    //     };
    
    //     const handlePaymentOptionChange = (e) => {
    //         setPaymentOption(e.target.value);
    //     };
    //     if (!cartItems || cartItems.length === 0) {
    //         return (
    //             <div>
    //                 <p>Your shopping cart is empty.</p>
    //                 <a href="/">Return to Home Page</a>
    //             </div>
    //         );
    //     }
    //     const cartItemsCount = cartItems.length;
    //     // const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.price || 0), 0);
    //     const totalPrice = Array.isArray(cartItems) ? cartItems.reduce((total, item) => total + parseFloat(item.price || 0), 0) : 0;

    
    //     const removeFromCart = (itemId) => {
    //         axios.delete(`${baseUrl}/api/cart/${itemId}`)
    //             .then((response) => {
    //                 if (response.data.message === 'Cart item deleted successfully') {
    //                     // Manually filter out the deleted item from cartItems
    //                     const updatedCartItems = cartItems.filter(item => item.id !== itemId);
    //                     setCartItems(updatedCartItems);
    //                 }
    //             })
    //             .catch(error => {
    //                 // It's a good practice to handle potential errors.
    //                 console.error('Error deleting cart item:', error);
    //             });
    //     };

    
 

    //     const updateLocalStorage = (newCartItems) => {
    //         localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    //     };
    
    //     const loadCartItemsFromLocalStorage = () => {
    //         const storedCartItems = localStorage.getItem('cartItems');
    //         if (storedCartItems) {
    //             setCartItems(JSON.parse(storedCartItems));
    //         } else {
    //             setCartItems([]); // Ensure it's an array if nothing is stored
    //         }
    //         setLoading(false);
    //     };
    //     if (loading) {
    //         return <div>Loading...</div>;
    //     }
    
// const ShoppingCart = () => {
//     const { cartItems, setCartItems } = useCart();
//     const [newItem, setNewItem] = useState({});
//     const [paymentOption, setPaymentOption] = useState('');
//     const [loading, setLoading] = useState(true);
//     const cartItemsCount = cartItems.length;
//     // Load cart items from local storage on initial render
//     useEffect(() => {
//         const loadCartItemsFromLocalStorage = () => {
//             const storedCartItems = localStorage.getItem('cartItems');
//             if (storedCartItems) {
//                 setCartItems(JSON.parse(storedCartItems));
//             } else {
//                 setCartItems([]);
//             }
//             setLoading(false); // Update loading state here
//         };

//         loadCartItemsFromLocalStorage();
//     }, [setCartItems]); // Dependency array to ensure it only runs once

//     // Update local storage whenever cartItems change
//     useEffect(() => {
//         const updateLocalStorage = (newCartItems) => {
//             localStorage.setItem('cartItems', JSON.stringify(newCartItems));
//         };

//         updateLocalStorage(cartItems);
//     }, [cartItems]); // Dependency array to listen for changes in cartItems

//     // Handler to add item to cart
//     const handleAddItem = () => {
//         setCartItems([...cartItems, newItem]);
//         setNewItem({});
//     };

//     // Handler to change payment option
//     const handlePaymentOptionChange = (e) => {
//         setPaymentOption(e.target.value);
//     };

//     // Handler to remove item from cart
//     const removeFromCart = (itemId) => {
//         axios.delete(`${baseUrl}/api/cart/${itemId}`)
//             .then((response) => {
//                 if (response.data.message === 'Cart item deleted successfully') {
//                     // Manually filter out the deleted item from cartItems
//                     const updatedCartItems = cartItems.filter(item => item.id !== itemId);
//                     setCartItems(updatedCartItems);
//                 }
//             })
//             .catch(error => {
//                 console.error('Error deleting cart item:', error);
//             });
//     };

//     // Conditional rendering based on cart items and loading state
//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (!cartItems || cartItems.length === 0) {
//         return (
//             <div>
//                 <p>Your shopping cart is empty.</p>
//                 <a href="/">Return to Home Page</a>
//             </div>
//         );
//     }

//     // Calculate total price
//     const totalPrice = Array.isArray(cartItems) ? cartItems.reduce((total, item) => total + parseFloat(item.price || 0), 0) : 0;
//         console.log('cartItems:', cartItems);
// const ShoppingCart = () => {
//     const { cartItems, setCartItems } = useCart();
//     const [newItem, setNewItem] = useState({});
//     const [paymentOption, setPaymentOption] = useState('');

//     // Function to load cart items from localStorage
//     const loadCartItemsFromLocalStorage = () => {
//         const storedCartItems = localStorage.getItem('cartItems');
//         if (storedCartItems) {
//             setCartItems(JSON.parse(storedCartItems));
//         }
//     };

//     // Function to update localStorage whenever cartItems change
//     useEffect(() => {
//         localStorage.setItem('cartItems', JSON.stringify(cartItems));
//     }, [cartItems]);

//     // Load cart items from localStorage when the component mounts
//     useEffect(() => {
//         loadCartItemsFromLocalStorage();
//     }, []);

//     // Handler functions
//     const handleAddItem = () => {
//         const updatedCartItems = [...cartItems, newItem];
//         setCartItems(updatedCartItems);
//         setNewItem({});
//     };

//     const removeFromCart = (itemId) => {
//         const updatedCartItems = cartItems.filter(item => item.id !== itemId);
//         setCartItems(updatedCartItems);
//     };

//     const handlePaymentOptionChange = (e) => {
//         setPaymentOption(e.target.value);
//     };

//     // Calculate total price
//     const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.price || 0), 0);
const ShoppingCart = () => {
  const { cartItems, setCartItems } = useCart();
  const [newItem, setNewItem] = useState({});
  const [paymentOption, setPaymentOption] = useState('');

  // Function to update localStorage whenever cartItems change
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  // Load cart items from localStorage when the component mounts
  useEffect(() => {
    // Moved function to load cart items from localStorage inside this useEffect
    const loadCartItemsFromLocalStorage = () => {
      const storedCartItems = localStorage.getItem('cartItems');
      if (storedCartItems) {
        setCartItems(JSON.parse(storedCartItems));
      }
    };

    loadCartItemsFromLocalStorage();
  }, [setCartItems]); // setCartItems is stable and doesn't change, so it's safe to use as a dependency

  // Handler functions
  const handleAddItem = () => {
    const updatedCartItems = [...cartItems, newItem];
    setCartItems(updatedCartItems);
    setNewItem({});
  };
  useEffect(() => {

    // Fetch cart items from the server
    axios.get(`${baseUrl}/api/cart`).then((response) => {
      setCartItems(response.data);
      // setCartItemsCount(response.data.length); // Update the cart items count
    });
  }, [setCartItems]);
  const removeFromCart = (itemId) => {
    const updatedCartItems = cartItems.filter(item => item.id !== itemId);
    setCartItems(updatedCartItems);
  };

  const handlePaymentOptionChange = (e) => {
    setPaymentOption(e.target.value);
  };

  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.price || 0), 0);
  const cartItemsCount = cartItems.length;


        return (
        <>
            <TopBar cartItemsCount={cartItemsCount} />

            <ShoppingCartContainer>
                <ShoppingCartHeading>Shopping Cart</ShoppingCartHeading>
                <CartContainer>
                    {/* <CartTitle>Cart</CartTitle> */}
                    {cartItems.map((item) => (
                        <CartItem key={item.id}>
                            <CartItemImage src={baseUrl + '/images/' + item.image} alt={item.name} />

                            <CartItemInfo>
                                <CartItemName>{item.name}</CartItemName>
                                <CartItemPrice>{item.price} €</CartItemPrice>
                                <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
                            </CartItemInfo>
                        </CartItem>
                    ))}
                </CartContainer>
       
                <AddItemSection>
                    <h3>Add Item:</h3>
                    <Input
                        type="text"
                        placeholder="Product Name"
                        value={newItem.name || ''}
                        onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                    />
                    <Input
                        type="number"
                        placeholder="Price"
                        value={newItem.price || ''}
                        onChange={(e) => setNewItem({ ...newItem, price: e.target.value })}
                    />
                    <CartButtonWrapper>
                        <CartButton onClick={handleAddItem}>Add to Cart</CartButton>
                    </CartButtonWrapper>
                </AddItemSection>

                <PaymentOptionsSection>
                    <h3>Payment Options:</h3>
                    <PaymentOptionLabel>
                        <input
                            type="radio"
                            value="card"
                            checked={paymentOption === 'card'}
                            onChange={handlePaymentOptionChange}
                        />
                        Pay with Card
                    </PaymentOptionLabel>
                    <PaymentOptionLabel>
                        <input
                            type="radio"
                            value="paypal"
                            checked={paymentOption === 'paypal'}
                            onChange={handlePaymentOptionChange}
                        />
                        Pay with PayPal
                    </PaymentOptionLabel>
                    <CartButtonWrapper>
                        <CartButton primary="true">Proceed to Payment</CartButton>
                    </CartButtonWrapper>
                </PaymentOptionsSection>

                {/* Display the total price */}
                <p>Total Price: {totalPrice} €</p>
            </ShoppingCartContainer>
        </>
    );
};

export default ShoppingCart;
