// import React from 'react';
// import styled, { keyframes } from 'styled-components';

// const Container = styled.div`
//   width: 100%;
//   overflow: hidden;
// `;

// const animate = keyframes`
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(calc(-100% * 3)); /* Adjust the number of images */
//   }
// `;

// const ImageRow = styled.div`
//   width: calc(100% * 3); /* Adjust based on the number of images */
//   display: flex;
//   animation: ${animate} 100s linear infinite;
// `;

// const Image = styled.img`
//   width: 100%;
//   height: auto;
// `;

// const ImageAnimation = ({ images }) => {
//   return (
//     <Container>
//       <ImageRow>
//         {images.map((imageUrl, index) => (
//           <Image key={index} src={imageUrl} alt={`Image ${index}`} />
//         ))}
//       </ImageRow>
//     </Container>
//   );
// };

// export default ImageAnimation;
import React, { useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const animate = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% * 3)); /* Adjust the number of frames */
  }
`;

const ImageRow = styled.div`
  width: calc(100% * 3); /* Adjust based on the number of frames */
  height: auto; /* Adjust the height of your sprite sheet */
  background-image: url(${props => props.spriteSheet});
  background-size: cover;
  animation: ${animate} 10s linear infinite;
`;

const ImageAnimation = ({ spriteSheet }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.style.backgroundImage = `url(${entry.target.dataset.src})`;
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const imagesToObserve = containerRef.current.querySelectorAll('[data-src]');
    imagesToObserve.forEach(image => observer.observe(image));

    return () => observer.disconnect();
  }, []);

  return (
    <Container ref={containerRef}>
      <ImageRow spriteSheet={spriteSheet}></ImageRow>
    </Container>
  );
};

export default ImageAnimation;
