/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styled from 'styled-components';

import img777 from '../assets/ANI.PNG';
import img21 from '../assets/img21.jpg';
import img888 from '../assets/img888.PNG';


// Install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const ImageSliderContainer = styled.div`
  max-height: 600px;
  overflow: hidden;
  padding: 1%;
`;

const ImageSlider = () => {
    const images = [img777, img21, img888];

    return (
        <ImageSliderContainer>
            <div style={{ height: '300px', overflow: 'hidden' }}>
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        <Swiper
                            spaceBetween={30}
                            centeredSlides="true"
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            pagination={{ clickable: true }}
                        >
                            {images.map((imageUrl, index) => (
                                <SwiperSlide key={index} style={{ transform: 'none' }}>
                                    <img
                                        src={imageUrl}
                                        alt={`Image ${index + 1}`}
                                        style={{ width: '100%', height: '300px', objectFit: 'cover' }}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </ImageSliderContainer>
    );
};

export default ImageSlider;
