/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useRef, useState } from "react";
// import { AuthContext } from "../../App";
import { useEffect } from "react";
// import "../topbar/topbar.css";
import "../menuBurger/menu.css";
import { FaBars, FaShoppingCart } from 'react-icons/fa';
import Cookies from "js-cookie";
// import MenuIcon from "@material-ui/icons/Menu";
// import { useAuthDispatch, useAuthState } from "../../context/auth";
export default (props) => {
  // const { user, dispatch } = React.useContext(AuthContext);
  // const { user } = useAuthState();
  const [open, setOpen] = useState(false);
  const dropdownwrapper = useRef(null);
  const logout = () => {
    // authDispatch({ type: "LOGOUT" });
    localStorage.clear();
    Cookies.remove("token");
    window.location.href = "/login";
  };
  // const authDispatch = useAuthDispatch();
  const handleClickOutside = (event) => {
    if (
      dropdownwrapper.current &&
      !dropdownwrapper.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // clean
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div className="dropdownwrapper" ref={dropdownwrapper}>
      <FaBars onClick={() => setOpen(!open)} {...props} />
      {open && (
        <div>
          <div className="dropdpper">
            <ul className="modaly">
              <a className="menu-item" href="/">
                Home
              </a>
              <a className="menu-item" href="/addproduct">
                addproduct
              </a>
              <a className="menu-item" href="/cart">
                Cart
              </a>
              <a className="menu-item" href="/store">
                store
              </a>
              <a
  className="menu-item" 
  // href={"/"}
  // onClick={() =>
  //   dispatch({
  //     type: "X",
  //   })
  // }
  onClick={logout}
>
  deconexion
</a>
              {/* <a className="menu-item" href={"/profile/" + user.id}>
                Profile de {user.username}
              </a> */}
              {/* <a className="menu-item" href={"/admin/" + user.id}>
                admin
              </a> */}

            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

//<a
  //className="menu-item" 
  // href={"/"}
  // onClick={() =>
  //   dispatch({
  //     type: "X",
  //   })
  // }
//   onClick={logout}
// >
//   deconexion
// </a>